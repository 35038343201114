import React, { ChangeEvent, useEffect, useState } from 'react'
import { Language, Template, TemplateRequest } from '../../services/models'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useTemplateService } from '../../hooks/use-template-service'
import { useLanguageService } from '../../hooks/use-language-service'
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material'

interface EditProps {
    template?: Template
    onCancel: () => void
}

export const Edit: React.FC<EditProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()
    const [t] = useTranslation()
    const [error, setError] = useState<string>()

    const [languages, setLanguages] = useState<Language[]>()
    const [loading, setLoading] = useState(false)
    const [fileNames, setFileNames] = useState<string[]>([])

    const [templateService] = useTemplateService()
    const [languageService] = useLanguageService()

    useEffect(() => {
        if (!token) {
            return
        }
        setLoading(true)
        void languageService.list(token)
            .then(res => {
                res.map(languages => {
                    setLanguages(languages)
                    setLoading(false)
                }).mapError(e => {
                    setError(e.error)
                    setLoading(false)
                })
            })
    }, [token])

    const setAttachments = async (form: FieldValues, templateId: string) => {
        if (!token) {
            return
        }
        if (form.attachment && form.attachment.length > 0) {
            const res = await templateService.setAttachments(token, templateId, form.attachment)
            res.mapError(e => {
                setError(e.error)
            })
        }
    }

    const onSubmit = (form: FieldValues) => {
        if (!token) {
            return
        }
        setLoading(true)
        const request: TemplateRequest = {
            id: props.template?.id,
            subject: form.subject,
            description: form.description,
            body: form.body,
            language: form.language,
            tags: []
        }
        if (form.tags) {
            const arr: string[] = form.tags.split(',')
            arr.forEach(t => {
                request.tags?.push(t.trim().toLowerCase())
            })
        }
        if (!props.template) {
            void templateService.create(token, request).then(res => {
                res
                    .map(r => {
                        void setAttachments(form, r.id).then(() => {
                            setLoading(false)
                            props.onCancel()
                        })
                    })
                    .mapError(e => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        } else {
            void templateService.update(token, request).then(res => {
                res
                    .map(r => {
                        void setAttachments(form, r.id).then(() => {
                            setLoading(false)
                            props.onCancel()
                        })
                    })
                    .mapError(e => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        }
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }
        const files = e.target.files
        const fileNames: string[] = []
        for (let i = 0; i < files.length; i++) {
            fileNames.push(files[i].name)
        }

        setFileNames(fileNames)
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('Subject')}
                        error={errors.subject !== undefined}
                        helperText={errors.subject !== undefined ? 'Subject is required' : ''}
                        defaultValue={props.template?.subject}
                        {...register('subject', { required: true })}/>

                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('Description')}
                        error={errors.description !== undefined}
                        helperText={errors.description !== undefined ? 'Description is required' : ''}
                        defaultValue={props.template?.description}
                        {...register('description', { required: true })}/>

                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        multiline={true}
                        rows={15}
                        disabled={loading}
                        label={t('Template')}
                        error={errors.body !== undefined}
                        helperText={errors.body !== undefined ? 'Template is required' : ''}
                        defaultValue={props.template?.body}
                        {...register('body', { required: true })}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('Tags')}
                        defaultValue={props.template?.tags.join(',')}
                        {...register('tags')}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Grid container>
                        <Button
                            variant="outlined"
                            component="label"
                            disabled={loading}
                        >
                            <Trans t={t}>
                                Upload files
                            </Trans>
                            <input
                                type="file"
                                multiple={true}
                                accept="application/pdf,application/vnd.ms-excel"
                                hidden
                                {...register('attachment', { onChange: handleFileUpload })}
                            />
                        </Button>
                        <Box style={{ padding: '10px ' }}>
                            {fileNames.map(f => <Typography key={f} component="div" variant="body1">
                                {f}
                            </Typography>)}
                        </Box>
                        {props.template?.attachments && <Box>{props.template.attachments.length} files</Box>}
                    </Grid>
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <InputLabel id="language-label">Language</InputLabel>
                    <Select
                        labelId="language-label"
                        label="Language"
                        disabled={loading}
                        defaultValue={props.template?.language}
                        {...register('language')}
                    >
                        {languages?.map(v => {
                            return <MenuItem key={v.key} value={v.key}>
                                {v.name}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                            Save
                        </Trans>
                    </Button>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                            Cancel
                        </Trans>
                    </Button>
                </FormControl>
            </Box>
        </div>
    )
}
