import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useEffect, useState } from 'react'
import { Course, GridData, UserFilter } from '../../../services/models'
import { defaultPageSize } from '../../utils/grid-utils'
import { useCourseService } from '../../../hooks/use-course-service'
import { useUsersService } from '../../../hooks/use-users-service'

export const useCourses = (loadUsers?: boolean) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [filter, setFilter] = useState<UserFilter>()
    const [limit, setLimit] = useState<number>(defaultPageSize)
    const [skip, setSkip] = useState<number>()
    const [sort, setSort] = useState<string | undefined>('creationDate Descending')
    const [data, setData] = useState<GridData<Course>>({ items: [], totalRows: 0 })

    const [courseService] = useCourseService()
    const [userService] = useUsersService()

    const loadCourses = async () => {
        let courses: GridData<Course> = { items: [], totalRows: 0 }
        if (!token) {
            setData(courses)
            return
        }
        setLoading(true)
        const res = await courseService.find(token, filter, limit, skip, sort)
        res.map(r => {
            courses = r
        }).mapError(e => {
            setError(e.error)
        })
        if (loadUsers) {
            const users = await userService.find(token, { user: courses.items.map(i => i.authorId) })
            users.map(u => {
                courses.items.forEach(a => {
                    const userInfo = u.items.find(i => i.id === a.authorId)
                    a.authorId = userService.getDescription(userInfo)
                })
            })
        }
        setLoading(false)
        setData(courses)
    }

    const getCourses = async (id?: string | string[]): Promise<Course[] | undefined> => {
        if (!token || !id || (Array.isArray(id) && id.length === 0)) {
            return undefined
        }
        const res = await courseService.find(token, { id: Array.isArray(id) ? id : [id] })
        // eslint-disable-next-line no-undef-init
        let courses: Course[] | undefined = undefined
        res.map(r => {
            courses = r.items
        })
        return courses
    }

    const setCurrentPage = (page: number) => {
        setSkip(limit * page)
    }

    const refresh = async () => {
        await loadCourses()
    }

    useEffect(() => {
        loadCourses().catch(e => setError(e))
    }, [token, filter, limit, skip, sort, loadUsers])

    return {
        data,
        getCourses,
        refresh,
        setFilter,
        setLimit,
        setCurrentPage,
        setSort,
        loading,
        error
    }
}
