import { IProcessTemplateService } from '../process-template-service'
import config from '../../config.json'
import {
    GridData,
    OperationStatus, ProcessTemplate,
    ProcessTemplateFilter, ProcessTemplateRequest
} from '../models'
import { del, get, post, put } from '../connector'

export class ProcessTemplateService implements IProcessTemplateService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_PROCESSOR_SERVICE_API_HOST ??
            config.servers.processorServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'REACT_APP_PROCESSOR_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, filter?: ProcessTemplateFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}template/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<ProcessTemplate>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    create = async (token: string, request: ProcessTemplateRequest) => {
        const url = `${this.apiUrl}template`
        return await post<ProcessTemplateRequest, OperationStatus>(token, url, request)
    }

    update = async (token: string, id: string, request: ProcessTemplateRequest) => {
        const url = `${this.apiUrl}template/${id}`
        return await put<ProcessTemplateRequest, OperationStatus>(token, url, request)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}template/${id}`
        return await del<OperationStatus>(token, url)
    }
}
