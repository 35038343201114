import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useUsersService } from '../../../hooks/use-users-service'
import { useStepTemplateService } from '../../../hooks/use-step-template-service'
import { useEffect, useState } from 'react'
import { GridData, StepTemplate, StepTemplateFilter } from '../../../services/models'
import { defaultPageSize } from '../../utils/grid-utils'

export const useStepTemplateData = () => {
    const token = useRecoilValue<string | undefined>(tokenSelector)

    const [stepTemplateService] = useStepTemplateService()
    const [userService] = useUsersService()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [filter, setFilter] = useState<StepTemplateFilter>()
    const [limit, setLimit] = useState<number>(defaultPageSize)
    const [skip, setSkip] = useState<number>()
    const [sort, setSort] = useState<string | undefined>('creationDate Descending')
    const [data, setData] = useState<GridData<StepTemplate>>({ items: [], totalRows: 0 })

    const loadRows = async () => {
        if (token) {
            setLoading(true)
            const articlesResult = await stepTemplateService.find(token, filter, limit, skip, sort)
            let templates: GridData<StepTemplate> = { items: [], totalRows: 0 }
            articlesResult
                .map((a) => {
                    templates = a
                })
                .mapError((e) => {
                    setError(e.error)
                    setLoading(false)
                })
            const usersResult = await userService.find(token, {
                user: templates.items.map((i) => i.authorId)
            })
            usersResult.map((u) => {
                templates.items.forEach((a) => {
                    const userInfo = u.items.find((i) => i.id === a.authorId)
                    if (userInfo) {
                        if (userInfo.profile.surname && userInfo.profile.firstName) {
                            a.authorId = `${userInfo.profile.surname} ${userInfo.profile.firstName.substring(0, 1)}`
                        } else {
                            a.authorId = userInfo.user.userName
                        }
                    }
                })
            })
            setData(templates)
            setLoading(false)
        }
    }

    const setCurrentPage = (page: number) => {
        setSkip(limit * page)
    }

    const refresh = async () => {
        await loadRows()
    }

    const getTemplate = async (id?: string | string[]): Promise<StepTemplate[] | undefined> => {
        if (!token || !id) {
            return undefined
        }
        const res = await stepTemplateService.find(token, { id: Array.isArray(id) ? id : [id] })
        // eslint-disable-next-line no-undef-init
        let user: StepTemplate[] | undefined = undefined
        res.map(r => {
            user = r.items
        })
        return user
    }

    useEffect(() => {
        loadRows().catch((e) => setError(e))
    }, [token, filter, limit, skip, sort])

    return {
        data,
        refresh,
        setFilter,
        setLimit,
        setCurrentPage,
        setSort,
        loading,
        error,
        getTemplate
    }
}
