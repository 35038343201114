import React, { useState } from 'react'
import { ProcessTemplate } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Edit } from './Edit'
import { List } from './List'

const processTemplatesRoutes = {
    list: '/processes/list',
    create: '/processes/create',
    edit: '/processes/edit'
}
export const ProcessTemplates: React.FC = () => {
    const [currentTemplate, setCurrentTemplate] = useState<ProcessTemplate>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const editPage = (processTemplate?: ProcessTemplate) => {
        setCurrentTemplate(processTemplate)
        navigate(processTemplate ? `${processTemplatesRoutes.edit}/${processTemplate?.id}` : processTemplatesRoutes.create)
    }

    const pagesMap = () => [
        {
            key: '/list',
            page: <List onCreate={(a) => editPage(a)} canDelete={true} />,
            title: t('Process templates')
        },
        {
            key: '/create',
            page: (
                <Edit
                    onCancel={() => {
                        navigate(processTemplatesRoutes.list)
                    }}
                    processTemplate={currentTemplate}
                />
            ),
            title: t('Create new template')
        },
        {
            key: '/edit/:id',
            page: (
                <Edit
                    onCancel={() => {
                        navigate(processTemplatesRoutes.list)
                    }}
                    processTemplate={currentTemplate}
                />
            ),
            title: currentTemplate ? currentTemplate.name : t('Create new template')
        }
    ]

    const pages = pagesMap()

    return (
        <div style={{ minHeight: '400px' }}>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>{pages.find((p) => p.key === location.pathname)?.title}</Trans>
                </Typography>
            </div>
            <Routes>
                {pages.map((m, i) => {
                    return <Route path={m.key} element={m.page} key={i} />
                })}
            </Routes>
        </div>
    )
}
