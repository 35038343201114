import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { History } from '../History'

interface HistoryMemberButtonProps {
    memberId: string
}

export const HistoryMemberButton: React.FC<HistoryMemberButtonProps> = (props) => {
    const [open, setOpen] = useState(false)
    const [t] = useTranslation()
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <React.Fragment>
            <Button onClick={() => {
                setOpen(true)
            }}>
                <Trans t={t}>
                    History
                </Trans>
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <DialogTitle>
                    <Trans t={t}>
                        History of member
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <History objectId={props.memberId} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <Trans t={t}>
                            Close
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
