import React, { useState } from 'react'
import { Course } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { List } from './List'
import { Edit } from './Edit'

const coursesRoutes = {
    list: '/courses/list',
    create: '/courses/create',
    edit: '/courses/edit'
}

export const Courses: React.FC = () => {
    const [currentCourse, setCurrentCourse] = useState<Course>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const editPage = (course?: Course) => {
        setCurrentCourse(course)
        navigate(course ? `${coursesRoutes.edit}/${course?.id}` : coursesRoutes.create)
    }

    const pagesMap = () => [
        {
            key: '/list',
            page: <List
                onCreate={(a) => editPage(a)}
                canDelete={true}
            />,
            title: t('Courses')
        },
        {
            key: '/create',
            page: <Edit
                onCancel={() => {
                    navigate(coursesRoutes.list)
                }}
                course={currentCourse}/>,
            title: t('Create new course')
        },
        {
            key: '/edit/:id',
            page: <Edit
                onCancel={() => {
                    navigate(coursesRoutes.list)
                }}
                course={currentCourse}/>,
            title: currentCourse ? currentCourse.title : t('Create new course')
        }
    ]

    const pages = pagesMap()

    return (
        <div style={{ minHeight: '400px' }}>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>
                        {pages.find(p => p.key === location.pathname)?.title}
                    </Trans>
                </Typography>
            </div>
            <Routes>
                {pages.map((m, i) => {
                    return <Route path={m.key} element={m.page} key={i}/>
                })}
            </Routes>
        </div>
    )
}
