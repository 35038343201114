import React, { useState } from 'react'
import { HistoryEventFilter } from '../../services/models'
import { Grid } from '@mui/material'
import { HistoryEventTypeSelector } from './HistoryEventTypeSelector'
import { useTranslation } from 'react-i18next'

interface HistoryFilterProps {
    filter?: HistoryEventFilter
    onSubmit: (filter: HistoryEventFilter) => void
}

export const HistoryFilter: React.FC<HistoryFilterProps> = (props) => {
    const [currentFilter, setCurrentFilter] = useState(props.filter)
    const [t] = useTranslation()
    return (

        <Grid container style={{ paddingBottom: '10px', paddingTop: '5px' }}>
            <HistoryEventTypeSelector
                label={t('Events')}
                multiselect={true}
                name={'event'}
                defaultValue={currentFilter?.event}
                onChange={(event) => {
                    const newFilter: HistoryEventFilter = {
                        objectId: currentFilter?.objectId,
                        objectType: currentFilter?.objectType,
                        event
                    }
                    setCurrentFilter(newFilter)
                    props.onSubmit(newFilter)
                }}
            />
        </Grid>
    )
}
