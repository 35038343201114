import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParticipantRequestService } from '../../../hooks/use-participant-request-service'
import { DeleteButton } from '../../shared/DeleteButton'
import { Alert } from '@mui/material'

interface DeleteParticipantRequestButtonProps {
    id: string
    callback: () => void
}
export const DeleteParticipantRequestButton: React.FC<DeleteParticipantRequestButtonProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [error, setError] = useState<string>()
    const [participantRequestService] = useParticipantRequestService()
    const [t] = useTranslation()

    const handleDelete = () => {
        if (!token || !props.id) {
            return
        }
        void participantRequestService.delete(token, props.id)
            .then(r => {
                r.map(rr => {
                    props.callback()
                }).mapError(e => setError(e.error))
            })
    }

    return (
        <React.Fragment>
            <DeleteButton
                confirmationText={t('Are you sure you want to remove request?')}
                header={t('Delete request')}
                handleDelete={handleDelete}
            />
            {error && <Alert severity="error">{error}</Alert>}
        </React.Fragment>
    )
}
