import React, { useState } from 'react'
import { Account } from '../../services/models'
import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { List } from './List'
import { Edit } from './Edit'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

const usersRoutes = {
    list: '/users/list',
    edit: '/users/edit'
}

export const Users: React.FC = () => {
    const [currentAccount, setCurrentAccount] = useState<Account | undefined>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const editPage = (account?: Account) => {
        setCurrentAccount(account)
        if (account) {
            navigate(`${usersRoutes.edit}/${account.id}`)
        }
    }

    const pagesMap = () => [
        {
            key: '/list',
            page: <List onCreate={editPage} canDelete={true}/>,
            title: t('Users')
        },
        {
            key: '/edit/:id',
            page: <Edit account={currentAccount} onCancel={() => navigate(usersRoutes.list)}/>,
            title: currentAccount ? currentAccount.user.email : t('Create new user')
        }
    ]

    const pages = pagesMap()

    return (
        <div>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>
                        {pages.find(p => p.key === location.pathname)?.title}
                    </Trans>
                </Typography>
            </div>
            <Routes>
                {pages.map((m, i) => {
                    return <Route path={m.key} element={m.page} key={i}/>
                })}
            </Routes>
        </div>
    )
}
