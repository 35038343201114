import React, { useState } from 'react'
import {
    Alert,
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField
} from '@mui/material'
import { useUsersService } from '../hooks/use-users-service'
import { Trans, useTranslation } from 'react-i18next'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useCaptchaContext } from '../hooks/CaptchaContext'
import { RegisterRequest } from '../services/models'

interface RegisterProps {
    onRegister: () => void
}

export const Register: React.FC<RegisterProps> = (props) => {
    const [userService] = useUsersService()
    const [t] = useTranslation()
    const [error, setError] = useState<string>()
    const [posting, setPosting] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const {
        handleSubmit,
        control
    } = useForm()
    const { recaptchaRef } = useCaptchaContext()

    const onSubmit = async (form: FieldValues) => {
        if (!recaptchaRef) {
            setError(t('Captcha recaptchaRef not found'))
            return
        }

        const token = await recaptchaRef.current?.executeAsync()

        if (token == null) {
            setError(t('Captcha token is empty'))
            return
        }

        if (form.password !== form.passwordReply) {
            setError(t('Password and repeated password must be equal!'))
            return
        }

        setPosting(true)

        const request: RegisterRequest = {
            user: {
                login: form.login,
                password: form.password,
                email: form.email
            },
            profile: {
                firstName: form.firstName,
                surname: form.surname
            },
            captcha: token
        }

        const res = await userService.register(request)
        res
            .map(r => {
                if (r.succeeded) {
                    setConfirmOpen(true)
                } else {
                    setError(r.error)
                }
            })
            .mapError(e => setError(e.data?.error ?? e.error))
        setPosting(false)

        recaptchaRef.current?.reset()
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'login'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Login is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'Login'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'password'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Password is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                type={'password'}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'Password'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'passwordReply'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Password is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                type={'password'}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'Repeat password'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'email'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Email is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                type={'email'}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'Email'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'firstName'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'FirstName is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'FirstName'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'surname'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Surname is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'Surname'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button variant="contained" disabled={posting} type="submit">
                        <Trans t={t}>
                            Register
                        </Trans>
                    </Button>
                </FormControl>
            </Box>
            <Dialog open={confirmOpen} onClose={() => {
                setConfirmOpen(false)
            }}>
                <DialogTitle>
                    <Trans t={t}>
                        Request has been sent
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans t={t}>
                            Thank you for request! Administrator should activate your account
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setConfirmOpen(false)
                        props.onRegister()
                    }}>
                        <Trans t={t}>
                            Ok
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
