import React, { useState } from 'react'
import { ParticipantRequest, RecordStatus } from '../../services/models'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { Trans, useTranslation } from 'react-i18next'
import { useParticipantRequestService } from '../../hooks/use-participant-request-service'
import { Alert, Box, Button, FormControl, Grid } from '@mui/material'
import { FieldValues, useForm } from 'react-hook-form'

interface ViewProps {
    request: ParticipantRequest
    onCancel: () => void
}

export const View: React.FC<ViewProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        register,
        handleSubmit
    } = useForm()

    const [t] = useTranslation()
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)

    const [participantRequestService] = useParticipantRequestService()

    const onSubmit = (form: FieldValues) => {
        if (!token) {
            return
        }
        setLoading(true)
        void participantRequestService.setStatus(token, {
            id: form.requestId,
            status: props.request.status === RecordStatus.New
                ? RecordStatus.Processing
                : (
                    props.request.status === RecordStatus.Processing ? RecordStatus.Processed : RecordStatus.New
                )
        }).then(res => {
            res.map(s => {
                if (!s.succeeded) {
                    setError(s.error)
                } else {
                    props.onCancel()
                }
                setLoading(false)
            }).mapError(e => {
                setError(e.error)
                setLoading(false)
            })
        })
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container style={{ padding: '20px' }}>
                    <Grid item xs={12} sm={2}>
                        Name
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.name}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        Type
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.typeOfContact}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        Nickname
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.nickname}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        Manager
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.manager}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        Creation date
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.creationDate}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        Update date
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.updateDate}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        Feedback
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {props.request.feedback}
                    </Grid>
                </Grid>
                <input type="hidden" {...register('requestId')} value={props.request.id}/>
                {props.request.status === RecordStatus.New && <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                            Start work
                        </Trans>
                    </Button>
                </FormControl>}
                {props.request.status === RecordStatus.Processing && <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                            Done
                        </Trans>
                    </Button>
                </FormControl>}
                {props.request.status === RecordStatus.Processed && <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                            Reopen
                        </Trans>
                    </Button>
                </FormControl>}
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                            Cancel
                        </Trans>
                    </Button>
                </FormControl>
            </Box>
        </div>
    )
}
