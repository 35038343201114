import React, { Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { Filter } from '../services/models'
import { defaultPageSize } from '../components/utils/grid-utils'

interface FilterContextType {
    filter?: Filter | undefined
    setFilter?: Dispatch<SetStateAction<Filter | undefined>> | undefined
    limit?: number | undefined
    setLimit?: Dispatch<React.SetStateAction<number | undefined>> | undefined
    skip?: number | undefined
    setSkip?: Dispatch<React.SetStateAction<number | undefined>> | undefined
    sort?: string | undefined
    setSort?: Dispatch<React.SetStateAction<string | undefined>> | undefined
}

const FilterContext = React.createContext<FilterContextType>(
    {
        filter: undefined,
        setFilter: undefined,
        limit: undefined,
        setLimit: undefined,
        skip: undefined,
        setSkip: undefined,
        sort: undefined,
        setSort: undefined
    })

export interface FilterContextProps extends FilterContextType {
    children: ReactNode
}

export const useFilterContext = () => useContext(FilterContext)

export const FilterContextProvider = (props: FilterContextProps) => {
    const [filter, setFilter] = useState(props.filter)
    const [limit, setLimit] = useState<number | undefined>(props.limit ?? defaultPageSize)
    const [skip, setSkip] = useState<number | undefined>(props.skip)
    const [sort, setSort] = useState<string | undefined>(props.sort ?? 'creationDate Descending')

    return (
        <FilterContext.Provider value={{ filter, setFilter, limit, setLimit, skip, setSkip, sort, setSort }}>
            {props.children}
        </FilterContext.Provider>
    )
}
