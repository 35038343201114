import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useParticipantRequestService } from '../../../hooks/use-participant-request-service'
import { useUsersService } from '../../../hooks/use-users-service'
import { useEffect, useState } from 'react'
import { GridData, ParticipantRequest, ParticipantRequestFilter } from '../../../services/models'
import { defaultPageSize } from '../../utils/grid-utils'

export const useParticipantsRequests = () => {
    const token = useRecoilValue<string | undefined>(tokenSelector)

    const [participantRequestService] = useParticipantRequestService()
    const [userService] = useUsersService()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [filter, setFilter] = useState<ParticipantRequestFilter>()
    const [limit, setLimit] = useState<number>(defaultPageSize)
    const [skip, setSkip] = useState<number>()
    const [sort, setSort] = useState<string | undefined>('creationDate Descending')
    const [data, setData] = useState<GridData<ParticipantRequest>>({ items: [], totalRows: 0 })

    const loadRows = async () => {
        if (token) {
            setLoading(true)
            const requestResult = await participantRequestService
                .find(token, limit, skip, sort)
            let requests: GridData<ParticipantRequest> = { items: [], totalRows: 0 }
            requestResult.map(r => {
                requests = r
            }).mapError(e => {
                setError(e.error)
                setLoading(false)
            })
            if (requests.totalRows > 0) {
                const usersResult = await userService
                    .find(token, {
                        user: requests.items.filter(i => i.manager).map(i => i.manager)
                    })
                usersResult.map(u => {
                    requests.items.forEach(a => {
                        if (a.manager) {
                            const userInfo = u.items.find(i => i.id === a.manager)
                            if (userInfo) {
                                if (userInfo.profile.surname && userInfo.profile.firstName) {
                                    a.manager = `${userInfo.profile.surname} ${userInfo.profile.firstName.substring(0, 1)}`
                                } else {
                                    a.manager = userInfo.user.userName
                                }
                            }
                        }
                    })
                })
            }
            setData(requests)
            setLoading(false)
        }
    }

    const setCurrentPage = (page: number) => {
        setSkip(limit * page)
    }

    const refresh = async () => {
        await loadRows()
    }

    useEffect(() => {
        loadRows().catch(e => setError(e))
    }, [token, filter, limit, skip, sort])

    return {
        data,
        refresh,
        setFilter,
        setLimit,
        setCurrentPage,
        setSort,
        loading,
        error
    }
}
