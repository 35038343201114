import { IRoleService } from '../role-service'
import config from '../../config.json'
import { GridData, Role } from '../models'
import { get } from '../connector'

export class RoleService implements IRoleService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_USERS_SERVICE_API_HOST ??
            config.servers.usersServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'USERS_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string) => {
        const url = `${this.apiUrl}role/find`

        return await get<GridData<Role>>(token, url)
    }
}
