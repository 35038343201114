import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useFileAdapter } from '../../hooks/use-file-adapter'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClassicEditor = require('ckeditor5-build-full')

interface TextEditorProps {
    text?: string
    onChange: (text: string) => void
}

export const TextEditor: React.FC<TextEditorProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [editorText, setEditorText] = useState(props.text)
    const [adapter] = useFileAdapter()

    return (
        <React.Fragment>
            <CKEditor
                editor={ ClassicEditor }
                data={editorText}
                onChange={ (event, editor) => {
                    const text = editor.getData()
                    setEditorText(text)
                    props.onChange(text)
                } }
                config={{
                    // plugins: [ FullScreen ],
                    // toolbar: { items: [ 'fullScreen' ] },
                    simpleUpload: {
                        uploadUrl: adapter.getUploadUrl(),
                        withCredentials: true,
                        headers: adapter.getHeaders(token)
                    }
                }}
            />
        </React.Fragment>
    )
}
