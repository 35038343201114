import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { Account } from '../../services/models'
import { useUsers } from './hooks/use-users'
import { Control } from 'react-hook-form/dist/types'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { Controller, FieldValues } from 'react-hook-form'

interface UserSelectorProps {
    name: string
    label: string
    required: boolean
    disabled?: boolean
    multiple?: boolean
    values?: string | string[]
    control?: Control
    setValue?: UseFormSetValue<FieldValues>
}

export const UserSelector: React.FC<UserSelectorProps> = (props) => {
    const { data, getUser, loading } = useUsers()

    const [values, setValues] = useState<Account | Account[] | null>([])
    useEffect(() => {
        void getUser(props.values).then(r => {
            if (r) {
                setValues(Array.isArray(props.values) ? r : r[0])
                if (props.setValue) {
                    props.setValue(props.name, Array.isArray(props.values) ? r.map(c => c?.id) : r[0]?.id)
                }
            }
        })
    }, [])

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.required ? { required: 'Select user' } : {}}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    {...field}
                    disablePortal
                    multiple={props.multiple}
                    options={data?.items || []}
                    disabled={loading || props.disabled}
                    value={values}
                    fullWidth={true}
                    isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id
                    }}
                    getOptionLabel={r => {
                        return r?.profile ? `${r.profile.surname} ${r.profile.firstName}` : 'Not selected'
                    }}
                    onChange={(event, value) => {
                        setValues(value)
                        if (props.setValue) {
                            let res: Array<Account | null> = []
                            const isArray = Array.isArray(value)
                            if (!isArray) {
                                res = [value]
                            } else {
                                res = value
                            }
                            props.setValue(props.name, !isArray ? res[0]?.id : res.map(c => c?.id))
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} error={!!error} helperText={error ? error.message : null} label={props.label} />}
                />
            )}
        />

    )
}
