import React, { useEffect, useState } from 'react'
import { SourceType } from '../../../services/models'
import { Control, ControllerRenderProps, FieldError } from 'react-hook-form/dist/types'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { Controller, FieldValues } from 'react-hook-form'
import { Autocomplete, TextField } from '@mui/material'

interface SourceTypeSelectorProps {
    label: string
    name: string
    required: boolean
    disabled?: boolean
    multiple?: boolean
    fullWidth?: boolean
    value?: SourceType | SourceType[]
    control?: Control
    setValue?: UseFormSetValue<FieldValues>
}
export const SourceTypeSelector: React.FC<SourceTypeSelectorProps> = (props) => {
    const [values, setValues] = useState<SourceType | SourceType[] | null>(props.value ?? null)

    useEffect(() => {
        setValues(props.value ?? null)
        if (props.setValue) {
            props.setValue(props.name, props.value)
        }
    }, [])

    const sourceTypes = [
        SourceType.Telegram,
        SourceType.Instagram,
        SourceType.Friends,
        SourceType.Others
    ]

    const select = (field: ControllerRenderProps<FieldValues, string>, error?: FieldError | undefined) => {
        return (
            <Autocomplete
                {...field}
                disablePortal
                multiple={props.multiple}
                fullWidth={props.fullWidth}
                options={sourceTypes}
                getOptionLabel={(option: SourceType) => {
                    return SourceType[option]
                }}
                value={values}
                onChange={(_event, value) => {
                    setValues(value)
                    if (props.setValue) {
                        let res: Array<SourceType | null>
                        const isArray = Array.isArray(value)
                        if (!isArray) {
                            res = [value]
                        } else {
                            res = value
                        }
                        props.setValue(props.name, !isArray ? res[0] : res)
                    }
                }}
                renderInput={(params) => <TextField
                    {...params}
                    error={!!error}
                    helperText={error ? error.message : null}
                    label={props.label} />}
            />
        )
    }

    return (
        <React.Fragment>
            {props.control && <Controller
                name={props.name}
                rules={props.required ? { required: 'Select source type' } : {}}
                control={props.control}
                render={({ field, fieldState: { error } }) => (
                    select(field, error)
                )}
            />}
            {!props.control && select({
                onBlur: () => {},
                onChange: () => {},
                name: props.name,
                value: props.value,
                ref: () => {}
            })}
        </React.Fragment>
    )
}
