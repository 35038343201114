import { IArticleService } from '../article-service'
import config from '../../config.json'
import { del, get, post, postFile, put } from '../connector'
import { Article, ArticleFilter, ArticleRequest, GridData, OperationStatus } from '../models'

export class ArticleService implements IArticleService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_CONTENT_SERVICE_API_HOST ??
            config.servers.contentServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'CONTENT_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, filter?: ArticleFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}article/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<Article>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    create = async (token: string, request: ArticleRequest) => {
        const url = `${this.apiUrl}article`
        return await post<ArticleRequest, OperationStatus>(token, url, request)
    }

    update = async (token: string, request: ArticleRequest) => {
        const url = `${this.apiUrl}article`
        return await put<ArticleRequest, OperationStatus>(token, url, request)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}article`
        return await del<OperationStatus>(token, url, { id })
    }

    setAvatar = async (token: string, articleId: string, files: FileList) => {
        const url = `${this.apiUrl}article/avatar?articleId=${articleId}`
        return await postFile<OperationStatus>(token, url, 'avatar', files)
    }
}
