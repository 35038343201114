import React, { useState } from 'react'
import { Box, FormControl } from '@mui/material'
import { MemberFilter, RecordStatus } from '../../services/models'
import { useTranslation } from 'react-i18next'
import { CourseSelector } from '../shared/CourseSelector'
import { RecordStatusSelector } from '../shared/RecordStatusSelector'

interface FilterProps {
    filter?: MemberFilter
    onSubmit: (filter: MemberFilter) => void
}

export const Filter: React.FC<FilterProps> = (props) => {
    const [selectedCourses, setSelectedCourses] = useState<string[]>(props.filter?.courses ?? [])
    const [selectedStatuses, setSelectedStatuses] = useState<RecordStatus[]>(props.filter?.status ?? [])
    const [t] = useTranslation()

    const onSubmit = (statuses: RecordStatus[], courses: string[]) => {
        const filter: MemberFilter = {
            status: statuses,
            courses
        }
        props.onSubmit(filter)
    }

    return (
        <div style={{ paddingBottom: '10px' }}>
            <Box
                component="form"
                method="POST"
                noValidate
            >
                <FormControl style={{ width: '500px' }} sx={{ m: 1 }}>
                    <CourseSelector
                        name={'selectCourse'}
                        label={'Course'}
                        required={false}
                        multiple={true}
                        values={selectedCourses}
                        setValue={(name: string, value: string[]) => {
                            setSelectedCourses(value)
                            onSubmit(selectedStatuses, value)
                        }}
                    />
                </FormControl>
                <FormControl style={{ width: '400px' }} sx={{ m: 1 }}>
                    <RecordStatusSelector
                        label={t('Status')}
                        multiple={true}
                        required={false}
                        value={selectedStatuses}
                        setValue={(name: string, value: RecordStatus[]) => {
                            setSelectedStatuses(value)
                            onSubmit(value, selectedCourses)
                        }}
                        name={'recordStatusSelector'}
                    />
                </FormControl>
            </Box>
        </div>
    )
}
