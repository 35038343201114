import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useCourses } from './hooks/use-courses'
import { Course } from '../../services/models'
import { Control, ControllerRenderProps, FieldError } from 'react-hook-form/dist/types'
import { Controller, FieldValues } from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'

interface CourseSelectorProps {
    name: string
    label: string
    required: boolean
    fullWidth?: boolean
    disabled?: boolean
    multiple?: boolean
    values?: string | string[]
    control?: Control
    setValue?: UseFormSetValue<FieldValues>
}

export const CourseSelector: React.FC<CourseSelectorProps> = (props) => {
    const { data, loading, getCourses } = useCourses()

    const [values, setValues] = useState<Course | Course[] | null>([])
    useEffect(() => {
        void getCourses(props.values).then(r => {
            if (r) {
                setValues(Array.isArray(props.values) ? r : r[0])
                if (props.setValue) {
                    props.setValue(props.name, Array.isArray(props.values) ? r.map(c => c?.id) : r[0]?.id)
                }
            }
        })
    }, [])

    const autocomplete = (field: ControllerRenderProps<FieldValues, string>, error: FieldError | undefined) => {
        return (
            <Autocomplete
                {...field}
                disablePortal
                multiple={props.multiple}
                fullWidth={props.fullWidth}
                options={data?.items || []}
                value={values}
                disabled={loading || props.disabled}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: Course) => option?.title ?? 'Not selected'}
                onChange={(event, value) => {
                    setValues(value)
                    if (props.setValue) {
                        let res: Array<Course | null> = []
                        const isArray = Array.isArray(value)
                        if (!isArray) {
                            res = [value]
                        } else {
                            res = value
                        }
                        props.setValue(props.name, !isArray ? res[0]?.id : res.map(c => c?.id))
                    }
                }}
                loading={loading}
                renderInput={(params) =>
                    <TextField {...params} error={!!error} helperText={error ? error.message : null} label={props.label} />}
            />
        )
    }

    return (
        <React.Fragment>
            {props.control && <Controller
                name={props.name}
                control={props.control}
                rules={props.required ? { required: 'Select course' } : {}}
                render={({ field, fieldState: { error } }) => (
                    autocomplete(field, error)
                )}
            />}
            {!props.control && autocomplete({
                onChange: () => {},
                value: props.values,
                onBlur: () => {},
                name: props.name,
                ref: () => {}
            }, undefined)}
        </React.Fragment>
    )
}
