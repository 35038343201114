import { IParticipantRequestService } from '../participant-request-service'
import config from '../../config.json'
import { del, get, post, put } from '../connector'
import { ChangeStatusRequest, GridData, OperationStatus, ParticipantRequest } from '../models'

export class ParticipantRequestService implements IParticipantRequestService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_USERS_SERVICE_API_HOST ??
            config.servers.usersServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'USERS_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}participant/find`
        return await get<GridData<ParticipantRequest>>(token, url, {
            limit,
            skip,
            sort
        })
    }

    create = async (token: string, request: ParticipantRequest) => {
        const url = `${this.apiUrl}participant`
        return await post<ParticipantRequest, OperationStatus>(token, url, request)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}participant`
        return await del<OperationStatus>(token, url, { id })
    }

    setStatus = async (token: string, request: ChangeStatusRequest) => {
        const url = `${this.apiUrl}participant/status`
        return await put<ChangeStatusRequest, OperationStatus>(token, url, request)
    }
}
