import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useMemberService } from '../../../hooks/use-member-service'
import { useTranslation } from 'react-i18next'
import { DeleteButton } from '../../shared/DeleteButton'
import { Alert } from '@mui/material'

interface DeleteMemberButtonProps {
    memberId: string
    callback: () => void
}

export const DeleteMemberButton: React.FC<DeleteMemberButtonProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [error, setError] = useState<string>()
    const [memberService] = useMemberService()
    const [t] = useTranslation()

    const handleDelete = () => {
        if (!token || !props.memberId) {
            return
        }
        void memberService.delete(token, props.memberId)
            .then(r => {
                // eslint-disable-next-line array-callback-return
                r.map(() => {
                    props.callback()
                }).mapError(e => setError(e.error))
            })
    }

    return (
        <React.Fragment>
            <DeleteButton
                confirmationText={t('Are you sure you want to remove member?')}
                header={t('Delete member')}
                handleDelete={handleDelete}
            />
            {error && <Alert severity="error">{error}</Alert>}
        </React.Fragment>
    )
}
