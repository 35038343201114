import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { useTemplateService } from '../../hooks/use-template-service'
import { GridData, ListProps, Template } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { defaultPageSize } from '../utils/grid-utils'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { GridCallbackDetails } from '@mui/x-data-grid/models/api'
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel'

export const List: React.FC<ListProps<Template>> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)

    const [templateService] = useTemplateService()

    const [rows, setRows] = useState<GridData<Template>>({ items: [], totalRows: 0 })
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [error, setError] = useState<string>()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [currentTemplateId, setCurrentTemplateId] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)
    const [currentSort, setCurrentSort] = useState<string>()
    const [t] = useTranslation()

    const loadRows = (limit?: number, skip?: number, sort?: string) => {
        if (token) {
            setLoading(true)
            void templateService
                .find(token, undefined, limit, skip, sort)
                .then(templateResult => {
                    templateResult.map(templates => {
                        setRows(templates)
                        setLoading(false)
                    }).mapError(e => {
                        setError(e.error)
                        setLoading(false)
                    })
                })
        }
    }

    useEffect(() => {
        loadRows(defaultPageSize)
    }, [templateService, token])

    const columns: GridColDef[] = [
        { field: 'description', headerName: t('Name'), flex: 2 },
        {
            field: 'action',
            headerName: '',
            width: 100,
            renderCell: r => {
                return <Grid container direction={'row'}>
                    <IconButton onClick={() => {
                        props.onCreate(r.row)
                    }}>
                        <EditIcon />
                    </IconButton>
                    {props.canDelete && <IconButton onClick={() => {
                        setCurrentTemplateId(r.row.id)
                        setConfirmOpen(true)
                        props.onDelete?.(r.row)
                    }}>
                        <DeleteForeverIcon />
                    </IconButton>}
                </Grid>
            }
        }
    ]

    const onPageChange = (page: number, details: GridCallbackDetails) => {
        setCurrentPage(page)
        loadRows(defaultPageSize, page * defaultPageSize, currentSort)
    }

    const onSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
        const column = model.find(m => m.sort !== undefined)
        const sort = column ? `${column.field} ${column.sort === 'asc' ? 'Ascending' : 'Descending'}` : undefined
        if (column) {
            loadRows(defaultPageSize, currentPage * defaultPageSize, sort)
        } else {
            loadRows(defaultPageSize, currentPage * defaultPageSize)
        }
        setCurrentSort(sort)
    }

    const handleClose = () => {
        setConfirmOpen(false)
    }

    const handleDelete = () => {
        if (!token || !currentTemplateId) {
            return
        }
        void templateService.delete(token, currentTemplateId)
            .then(r => {
                r.map(() => {
                    loadRows(defaultPageSize, currentPage * defaultPageSize, currentSort)
                    setConfirmOpen(false)
                }).mapError(e => setError(e.error))
            })
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <div style={{ padding: '10px 0' }}>
                <Button variant="contained" onClick={() => props.onCreate()}>
                    <Trans t={t}>
                        Create
                    </Trans>
                </Button>
            </div>
            <DataGrid
                loading={loading}
                autoHeight={true}
                rows={rows.items}
                columns={columns}
                onPageChange={onPageChange}
                sortingMode={'server'}
                onSortModelChange={onSortModelChange}
                rowCount={rows.totalRows}
                pageSize={defaultPageSize}
                rowsPerPageOptions={[5]}
                paginationMode="server"
            />
            <Dialog open={confirmOpen} onClose={handleClose}>
                <DialogTitle>
                    <Trans t={t}>
                        Delete template
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove template?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
