import React, { useState } from 'react'
import { MainMenu } from './components/MainMenu'
import { Users } from './components/users/Users'
import './styles/App.css'
import { Login } from './components/Login'
import { atom, selector, useRecoilState } from 'recoil'
import { Articles } from './components/articles/Articles'
import { Alert, Grid } from '@mui/material'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Courses } from './components/courses/Courses'
import { Requests } from './components/participant-requests/Requests'
import { Members } from './components/members/Members'
import { Templates } from './components/templates/Templates'
import { CaptchaContextProvider } from './hooks/CaptchaContext'
import { Register } from './components/Register'
import { StepTemplates } from './components/process-steps/StepTemplates'
import { ProcessTemplates } from './components/processes/ProcessTemplates'

/**
 * Atom for savin token
 * */
const loginState = atom<string | undefined>({
    key: 'token',
    default: undefined
})

/**
 * Selector for getting token from state
 * */
export const tokenSelector = selector({
    key: 'tokenSelector',
    get: ({ get }) => {
        return get(loginState)
    }
})

/**
 * Main app
 * */
const App = () => {
    const [token, setToken] = useRecoilState(loginState)
    const [error, setError] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const onLogin = (token: string) => {
        setToken(token)
    }

    const onRegister = () => {
        navigate('/')
    }

    const isLogin = !token && !location.pathname.includes('/register')

    return (
        <React.Fragment>
            <CaptchaContextProvider>
                {isLogin && <div className="loginContainer">
                    {error && <Alert severity={'error'}>{error}</Alert>}
                    <Login onLogin={onLogin} onError={(e) => {
                        setError(e.error)
                    }}/>
                </div>}
                {!isLogin && <Grid container alignItems={'stretch'} direction="row">
                    <Grid item xs={12} style={{ height: '40px', padding: '0px 10px' }}>
                        <MainMenu/>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ padding: '20px' }}>
                            <Routes>
                                <Route path={'/register'} element={<Register onRegister={onRegister}/>}/>
                                <Route path={'/users/*'} element={<Users/>}/>
                                <Route path={'/templates/*'} element={<Templates />}/>
                                <Route path={'/articles/*'} element={<Articles/>}/>
                                <Route path={'/courses/*'} element={<Courses/>}/>
                                <Route path={'/participants/*'} element={<Requests />}/>
                                <Route path={'/members/*'} element={<Members />}/>
                                <Route path={'/process-steps/*'} element={<StepTemplates />} />
                                <Route path={'/processes/*'} element={<ProcessTemplates />}/>
                            </Routes>
                        </div>
                    </Grid>
                </Grid>}
            </CaptchaContextProvider>
        </React.Fragment>
    )
}

export default App
