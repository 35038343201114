import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useMemberService } from '../../../hooks/use-member-service'
import { useUsersService } from '../../../hooks/use-users-service'
import { useEffect, useState } from 'react'
import { Account, GridData, HistoryEventFilter, MemberHistoryEvent } from '../../../services/models'
import { defaultPageSize } from '../../utils/grid-utils'

export const useMembersHistory = (objectId: string) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)

    const [memberService] = useMemberService()
    const [userService] = useUsersService()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [filter, setFilter] = useState<HistoryEventFilter | undefined>({
        objectId: [objectId]
    })
    const [limit, setLimit] = useState<number>(defaultPageSize)
    const [skip, setSkip] = useState<number>()
    const [sort, setSort] = useState<string | undefined>('timestamp Descending')
    const [data, setData] = useState<GridData<MemberHistoryEvent>>({ items: [], totalRows: 0 })

    const loadRows = async () => {
        if (token) {
            setLoading(true)
            const eventsResult = await memberService.history(token, filter, limit, skip, sort)
            let events: GridData<MemberHistoryEvent> = { items: [], totalRows: 0 }
            // eslint-disable-next-line array-callback-return
            eventsResult.map(m => {
                events = m
            }).mapError(e => {
                setError(e.error)
                setLoading(false)
            })
            const usersResult = await userService
                .find(token, {
                    user: events.items.map(i => i.authorId)
                })
            let users: GridData<Account> = { items: [], totalRows: 0 }
            // eslint-disable-next-line array-callback-return
            usersResult.map(u => {
                users = u
            })
            events.items.forEach(a => {
                const userInfo = users.items.find(i => i.id === a.authorId)
                if (userInfo) {
                    if (userInfo.profile.surname && userInfo.profile.firstName) {
                        a.authorId = `${userInfo.profile.surname} ${userInfo.profile.firstName.substring(0, 1)}`
                    } else {
                        a.authorId = userInfo.user.userName
                    }
                }
            })
            setLoading(false)
            setData(events)
        }
    }

    const setCurrentPage = (page: number) => {
        setSkip(limit * page)
    }

    const refresh = async () => {
        await loadRows()
    }

    useEffect(() => {
        loadRows().catch(e => setError(e))
    }, [token, filter, limit, skip, sort])

    return {
        data,
        refresh,
        setFilter,
        filter,
        setLimit,
        setCurrentPage,
        setSort,
        loading,
        error
    }
}
