import { IMemberService } from '../member-service'
import config from '../../config.json'
import { del, get, post, put } from '../connector'
import {
    GridData, HistoryEventFilter,
    Member,
    MemberFilter,
    MemberHistoryEvent,
    OperationStatus,
    SendEmailRequest,
    UpdateMemberRequest
} from '../models'

export class MemberService implements IMemberService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_USERS_SERVICE_API_HOST ??
            config.servers.usersServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'USERS_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, filter?: MemberFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}member/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<Member>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    history = async (token: string, filter?: HistoryEventFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}member/history`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<MemberHistoryEvent>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}member`
        return await del<OperationStatus>(token, url, { id })
    }

    update = async (token: string, request: UpdateMemberRequest) => {
        const url = `${this.apiUrl}member`
        return await put<UpdateMemberRequest, OperationStatus>(token, url, request)
    }

    sendEmail = async (token: string, message: SendEmailRequest) => {
        const url = `${this.apiUrl}member/email/send`
        return await post<SendEmailRequest, OperationStatus>(token, url, message)
    }
}
