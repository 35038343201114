import {
    Account,
    Course,
    GridData,
    Member, MemberHistoryEvent,
    UpdateMemberRequest
} from '../../../services/models'
import { useMemberService } from '../../../hooks/use-member-service'
import { useUsersService } from '../../../hooks/use-users-service'
import { useCourseService } from '../../../hooks/use-course-service'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useEffect, useState } from 'react'
import { useFilterContext } from '../../../hooks/FilterContext'

export const useMembersData = () => {
    const token = useRecoilValue<string | undefined>(tokenSelector)

    const [memberService] = useMemberService()
    const [userService] = useUsersService()
    const [courseService] = useCourseService()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const { filter, limit, skip, setSkip, sort } = useFilterContext()
    const [data, setData] = useState<GridData<Member>>({ items: [], totalRows: 0 })

    const loadRows = async () => {
        if (token) {
            setLoading(true)
            const membersResult = await memberService.find(token, filter, limit, skip, sort)
            let members: GridData<Member> = { items: [], totalRows: 0 }
            // eslint-disable-next-line array-callback-return
            membersResult.map(m => {
                members = m
            }).mapError(e => {
                setError(e.error)
                setLoading(false)
            })
            const [usersResult, coursesResult, historyResult] = await Promise.all([
                userService
                    .find(token, {
                        user: members.items.filter(i => i.manager).map(i => i.manager)
                    }),
                courseService.find(token, {
                    id: members.items.map(m => m.course)
                }),
                memberService.history(token, {
                    objectId: members.items.map(m => m.id),
                    event: [4, 5]
                })
            ])
            let users: GridData<Account> = { items: [], totalRows: 0 }
            let courses: GridData<Course> = { items: [], totalRows: 0 }
            let events: GridData<MemberHistoryEvent> = { items: [], totalRows: 0 }
            // eslint-disable-next-line array-callback-return
            usersResult.map(u => {
                users = u
            })
            // eslint-disable-next-line array-callback-return
            coursesResult.map(u => {
                courses = u
            })
            // eslint-disable-next-line array-callback-return
            historyResult.map(u => {
                events = u
            })
            members.items.forEach(a => {
                const userInfo = users.items.find(i => i.id === a.manager)
                if (userInfo) {
                    if (userInfo.profile.surname && userInfo.profile.firstName) {
                        a.managerTitle = `${userInfo.profile.surname} ${userInfo.profile.firstName.substring(0, 1)}`
                    } else {
                        a.managerTitle = userInfo.user.userName
                    }
                }
                const course = courses.items.find(i => i.id === a.course)
                if (course) {
                    a.courseTitle = course.title
                }
                const hasConfirm = events.items.find(i => i.objectId === a.id)
                a.confirmSent = hasConfirm !== undefined
            })
            setLoading(false)
            setData(members)
        }
    }

    const editRow = async (request: UpdateMemberRequest, callback?: () => Promise<void>) => {
        if (!token) {
            return
        }
        const result = await memberService.update(token, request)
        let hasError = false
        result
            .map(status => {
                if (!status.succeeded) {
                    setError('Error in update member!')
                    hasError = true
                }
            }).mapError(e => {
                setError(e.error)
                hasError = true
            })
        if (!hasError && callback) {
            await callback()
        }
    }

    const setCurrentPage = (page: number) => {
        if (limit && setSkip) {
            setSkip(limit * page)
        }
    }

    const refresh = async () => {
        await loadRows()
    }

    useEffect(() => {
        loadRows().catch(e => setError(e))
    }, [token, filter, limit, skip, sort])

    return {
        data,
        refresh,
        setCurrentPage,
        loading,
        error,
        editRow
    }
}
