import { Language, StepTemplate, StepTemplateRequest } from '../../services/models'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLanguageService } from '../../hooks/use-language-service'
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material'
import { useStepTemplateService } from '../../hooks/use-step-template-service'
import { StepTypeSelector } from './controls/StepTypeSelector'
import { useFileService } from '../../hooks/use-file-service'

interface EditProps {
    stepTemplate?: StepTemplate
    onCancel: () => void
}

export const Edit: React.FC<EditProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        handleSubmit,
        control,
        setValue,
        register
    } = useForm()
    const [t] = useTranslation()
    const { id } = useParams()
    const [current, setCurrent] = useState(props.stepTemplate)
    const [error, setError] = useState<string>()
    const [languages, setLanguages] = useState<Language[]>()
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState<string>()
    const [imageFileName, setImageFileName] = useState<string>()

    const [stepTemplateService] = useStepTemplateService()
    const [languageService] = useLanguageService()
    const [fileService] = useFileService()

    useEffect(() => {
        if (!token) {
            return
        }
        setLoading(true)
        void languageService.list(token).then((res) => {
            // eslint-disable-next-line array-callback-return
            res.map((languages) => {
                setLanguages(languages)
                setLoading(false)
            }).mapError((e) => {
                setError(e.error)
                setLoading(false)
            })
        })
    }, [token])

    useEffect(() => {
        if (!token) {
            return
        }
        if (id && !props.stepTemplate) {
            setLoading(true)
            void stepTemplateService.find(token, { id: [id] }).then((r) => {
                r.map((a) => {
                    setCurrent(a.items[0])
                    setLoading(false)
                }).mapError(e => {
                    setError(e.error)
                    setLoading(false)
                })
            })
        }
    }, [id, token])

    useEffect(() => {
        if (!token) {
            return
        }
        if (current?.file) {
            void fileService.info(token, current.file).then(r => {
                r.map(a => {
                    setFileName(a.name)
                })
            })
        }
        if (current?.image) {
            void fileService.info(token, current.image).then(r => {
                r.map(a => {
                    setImageFileName(a.name)
                })
            })
        }
    }, [id, token])

    const submitRequest = (form: FieldValues, file?: string, image?: string) => {
        if (!token) {
            setLoading(false)
            return
        }
        const request: StepTemplateRequest = {
            name: form.name,
            language: form.language,
            description: form.description,
            stepType: form.status,
            contentUrl: form.contentUrl,
            file,
            image,
            nextButtonCaption: form.nextButtonCaption,
            prevButtonCaption: form.prevButtonCaption
        }
        if (!current) {
            void stepTemplateService.create(token, request).then((res) => {
                res
                    // eslint-disable-next-line array-callback-return
                    .map((r) => {
                        setLoading(false)
                        props.onCancel()
                    })
                    .mapError((e) => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        } else {
            void stepTemplateService.update(token, current.id, request).then((res) => {
                res
                    // eslint-disable-next-line array-callback-return
                    .map((r) => {
                        setLoading(false)
                        props.onCancel()
                    })
                    .mapError((e) => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        }
    }

    const onSubmit = (form: FieldValues) => {
        setLoading(true)
        setError(undefined)
        if ((form.file && form.file.length > 0) || (form.image && form.image.length > 0)) {
            void uploadFile(form).then((res) => {
                if (res) {
                    submitRequest(form, res[0], res[1])
                }
            })
        } else {
            submitRequest(form)
        }
    }

    const uploadMediaFile = async (form: FieldValues) => {
        if (!token) {
            return undefined
        }
        if (form.file && form.file.length > 0) {
            const res = await fileService.upload(token, form.file)
            let file: string | undefined
            res.map((r) => (file = r.id)).mapError((e) => {
                setError(e.error)
            })
            return file
        }
        return undefined
    }

    const uploadImageFile = async (form: FieldValues) => {
        if (!token) {
            return undefined
        }
        if (form.image && form.image.length > 0) {
            const res = await fileService.upload(token, form.image)
            let file: string | undefined
            res.map((r) => (file = r.id)).mapError((e) => {
                setError(e.error)
            })
            return file
        }
        return undefined
    }

    const uploadFile = async (form: FieldValues) => {
        return await Promise.all([
            uploadMediaFile(form),
            uploadImageFile(form)
        ])
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }
        const file = e.target.files[0]
        const { name } = file
        setFileName(name)
    }

    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }
        const file = e.target.files[0]
        const { name } = file
        setImageFileName(name)
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            {(!id || current) && (
                <Box component="form" method="POST" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name={'name'}
                            control={control}
                            defaultValue={current?.name}
                            rules={{ required: 'Name is required' }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Name'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name={'description'}
                            control={control}
                            defaultValue={current?.description}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    multiline={true}
                                    rows={5}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Description'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <StepTypeSelector
                            label={t('Type')}
                            required={true}
                            name="status"
                            disabled={loading}
                            control={control}
                            setValue={setValue}
                            value={current?.stepType}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <InputLabel id="language-label">Language</InputLabel>
                        <Controller
                            name={'language'}
                            control={control}
                            defaultValue={current?.language}
                            render={({ field, fieldState: { error } }) => (
                                <Select {...field} labelId="language-label" disabled={loading}>
                                    {languages?.map((v) => {
                                        return (
                                            <MenuItem key={v.key} value={v.key}>
                                                {v.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name="contentUrl"
                            control={control}
                            defaultValue={current?.contentUrl}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Content URL'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name="nextButtonCaption"
                            control={control}
                            defaultValue={current?.nextButtonCaption}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Next button caption'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name="prevButtonCaption"
                            control={control}
                            defaultValue={current?.prevButtonCaption}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Prev button caption'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Grid container>
                            <Button variant="outlined" component="label" disabled={loading}>
                                <Trans t={t}>Upload content file</Trans>
                                <input type="file" hidden {...register('file', { onChange: handleFileUpload })} />
                            </Button>
                            <Box style={{ padding: '10px ' }}>
                                <Typography variant="body1">{fileName}</Typography>
                            </Box>
                        </Grid>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Grid container>
                            <Button variant="outlined" component="label" disabled={loading}>
                                <Trans t={t}>Upload image file</Trans>
                                <input type="file" hidden {...register('image', { onChange: handleImageUpload })} />
                            </Button>
                            <Box style={{ padding: '10px ' }}>
                                <Typography variant="body1">{imageFileName}</Typography>
                            </Box>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <Button disabled={loading} variant="contained" type="submit">
                            <Trans t={t}>Save</Trans>
                        </Button>
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                            <Trans t={t}>Cancel</Trans>
                        </Button>
                    </FormControl>
                </Box>
            )}
        </div>
    )
}
