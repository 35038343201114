// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Entity {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Filter {}
export interface GridData<T> {
    items: T[]
    totalRows: number
}

export interface ListProps<T> {
    onCreate: (entity?: T) => void
    canDelete: boolean
    onDelete?: (entity?: T) => void
}

export enum Pages {
    List = 'List',
    Edit = 'Edit',
}

export interface User extends Entity {
    id: string
    userName: string
    email: string
    emailConfirmed: boolean
    lockoutEnabled: boolean
    lockoutEnd: Date
    roles: string[]
}

export interface ConfirmUserRequest {
    userId: string
}

export interface BaseFilter extends Filter {
    id?: string[]
    text?: string
}

export type UserFilter = BaseFilter & {
    user?: string[]
}

export type CourseFilter = BaseFilter

export interface Profile extends Entity {
    firstName?: string
    surname?: string
    birthdate?: Date
}

export interface Account extends Entity {
    id: string
    user: User
    profile: Profile
}

interface UserRegisterRequest {
    login: string
    password: string
    email: string
}

export interface RegisterRequest {
    user: UserRegisterRequest
    profile: Profile
    captcha: string
}

export interface Content extends Entity {
    id: string
    status: number
    creationDate: Date
    updateDate: Date
    authorId: string
    authorOfLastUpdate: string
    avatar: string
    language: string
    tags: string[]
    description: string
    externalUrl: string
}

export type Article = Content & {
    title: string
    body: string
}

export enum EventType {
    Online,
    Offline,
}

export type Course = Content & {
    title: string
    eventType: EventType
    duration: number
    coverage: number
}

export interface ContentRequest extends Entity {
    id?: string
    language: string
    tags?: string[]
    description?: string
    externalUrl?: string
}

export type ArticleRequest = ContentRequest & {
    title: string
    body?: string
}

export type CourseRequest = ContentRequest & {
    title: string
    eventType: EventType
    duration: number
    coverage: number
}

export interface UpdateProfileRequest {
    userId: string
    firstName?: string
    surname?: string
    roles?: string[]
}

export interface OperationStatus {
    id: string
    succeeded: boolean
    error: string
}

export interface Language {
    key: string
    name: string
}

export interface ParticipantRequest extends Entity {
    id: string
    name: string
    typeOfContact: string
    nickname: string
    participantType: string
    feedback: string
    status: RecordStatus
    manager: string
    creationDate: string
    updateDate?: string
}

export interface ChangeStatusRequest {
    id: string
    status: RecordStatus
}

export enum RecordStatus {
    New = 0,
    Processing = 1,
    Processed = 2,
    Waiting = 3,
    Failed = 4,
    Offline = 5,
}

export enum Roles {
    Admin = 'admin',
    ContentManager = 'contentManager',
}

export enum SourceType {
    Instagram = 0,
    Telegram = 1,
    Friends = 2,
    Others = 3,
}

export interface Member extends Entity {
    id: string
    name: string
    typeOfContact: string
    nickname: string
    telegram: string
    stressLevel: number
    physicalSecurity: boolean
    physicalSecurityComment: string
    country: string
    goals: string
    status: RecordStatus
    manager: string
    managerTitle?: string
    creationDate: string
    updateDate?: string
    course: string
    courseTitle?: string
    tags: string[]
    confirmSent?: boolean
    sourceType: string
    sourceTypeText?: string
}

export type UpdateMemberRequest = Member

export type MemberFilter = BaseFilter & {
    typeOfContact?: string
    nickname?: string
    telegram?: string
    status?: RecordStatus[]
    courses?: string[]
}

export type ContentFilter = BaseFilter & {
    tags?: string[]
    language?: string
}

export type ArticleFilter = ContentFilter

export type ParticipantRequestFilter = BaseFilter

export interface TemplateRequest extends Entity {
    subject: string
    description: string
    body: string
    language: string
    tags: string[]
    id?: string
}

export interface Attachment {
    name: string
    url: string
    contentType: string
}

export type Template = TemplateRequest & {
    attachments?: Attachment[]
}

export type TemplateFilter = BaseFilter & {
    tags?: string[]
}

export interface SendEmailRequest {
    members: string[]
    subject: string
    body: string
    attachments?: Attachment[]
}

export enum HistoryEventType {
    create = 1,
    update = 2,
    delete = 3,
    sendConfirmation = 4,
    sendInvite = 5,
}

export interface BaseHistoryEvent {
    authorId: string
    objectId: string
    objectType: string
    timestamp: Date
}

export type MemberHistoryEvent = BaseHistoryEvent & {
    event: HistoryEventType
}

export interface HistoryEventFilter extends Filter {
    objectId?: string[]
    objectType?: string
    event?: number[]
}

export interface Role {
    id: string
    name: string
}

export type StepTemplateFilter = BaseFilter & {}

export enum StepType {
    Text = 0,
    Video = 1,
    Audio = 2,
    Form = 3,
}

export interface StepTemplate extends Entity {
    id: string
    name: string
    description?: string
    stepType: StepType
    creationDate: Date
    updateDate: Date
    authorId: string
    authorOfLastUpdate: string
    language: string
    file?: string
    contentUrl?: string
    image?: string
    nextButtonCaption?: string
    prevButtonCaption?: string
}

export interface StepTemplateRequest {
    name: string
    description?: string
    stepType: StepType
    language: string
    file?: string
    contentUrl?: string
    image?: string
    nextButtonCaption?: string
    prevButtonCaption?: string
}

export interface FileInfo {
    name: string
    contentType: string
}

export interface ProcessTemplate extends Entity {
    id: string
    name: string
    image?: string
    description?: string
    duration?: number
    language: string
    steps?: string[]
    creationDate: Date
    updateDate: Date
    authorId: string
    authorOfLastUpdate: string
    tags: string[]
}

export type ProcessTemplateFilter = BaseFilter & {
    tags?: string[]
    language?: string
}

export interface ProcessTemplateRequest {
    name: string
    description?: string
    duration?: number
    language: string
    steps?: string[]
    tags?: string[]
    image?: string
}
