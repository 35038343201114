import { IStepTemplateService } from '../step-template-service'
import config from '../../config.json'
import {
    GridData,
    OperationStatus,
    StepTemplate,
    StepTemplateFilter, StepTemplateRequest
} from '../models'
import { del, get, post, put } from '../connector'

export class StepTemplateService implements IStepTemplateService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_PROCESSOR_SERVICE_API_HOST ??
            config.servers.processorServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'REACT_APP_PROCESSOR_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, filter?: StepTemplateFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}step/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<StepTemplate>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    create = async (token: string, request: StepTemplateRequest) => {
        const url = `${this.apiUrl}step`
        return await post<StepTemplateRequest, OperationStatus>(token, url, request)
    }

    update = async (token: string, id: string, request: StepTemplateRequest) => {
        const url = `${this.apiUrl}step/${id}`
        return await put<StepTemplateRequest, OperationStatus>(token, url, request)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}step/${id}`
        return await del<OperationStatus>(token, url)
    }
}
