import config from '../../config.json'
import { del, get, post, postFile, put } from '../connector'
import { Course, CourseFilter, CourseRequest, GridData, OperationStatus } from '../models'
import { ICourseService } from '../course-service'

export class CourseService implements ICourseService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_CONTENT_SERVICE_API_HOST ??
            config.servers.contentServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'CONTENT_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, filter?: CourseFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}course/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<Course>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    create = async (token: string, request: CourseRequest) => {
        const url = `${this.apiUrl}course`
        return await post<CourseRequest, OperationStatus>(token, url, request)
    }

    update = async (token: string, request: CourseRequest) => {
        const url = `${this.apiUrl}course`
        return await put<CourseRequest, OperationStatus>(token, url, request)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}course`
        return await del<OperationStatus>(token, url, { id })
    }

    setAvatar = async (token: string, courseId: string, files: FileList) => {
        const url = `${this.apiUrl}course/avatar?courseId=${courseId}`
        return await postFile<OperationStatus>(token, url, 'avatar', files)
    }
}
