import React, { ChangeEvent, useEffect, useState } from 'react'
import { Article, ArticleRequest, Language } from '../../services/models'
import { TextEditor } from '../shared/TextEditor'
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel, MenuItem, Select,
    TextField,
    Typography
} from '@mui/material'
import { FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useArticleService } from '../../hooks/use-article-service'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { useLanguageService } from '../../hooks/use-language-service'
import { useParams } from 'react-router-dom'

interface EditProps {
    article?: Article
    onCancel: () => void
}

export const Edit: React.FC<EditProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()
    const [t] = useTranslation()
    const { id } = useParams()
    const [current, setCurrent] = useState(props.article)
    const [error, setError] = useState<string>()
    const [body, setBody] = useState<string | undefined>(props.article?.body)
    const [avatarName, setAvatarName] = useState<string>()
    const [languages, setLanguages] = useState<Language[]>()
    const [loading, setLoading] = useState(false)

    const [articleService] = useArticleService()
    const [languageService] = useLanguageService()

    useEffect(() => {
        if (!token) {
            return
        }
        setLoading(true)
        void languageService.list(token)
            .then(res => {
                // eslint-disable-next-line array-callback-return
                res.map(languages => {
                    setLanguages(languages)
                    setLoading(false)
                }).mapError(e => {
                    setError(e.error)
                    setLoading(false)
                })
            })
    }, [token])

    useEffect(() => {
        if (!token) {
            return
        }
        if (id && !props.article) {
            void articleService.find(token, { id: [id] }).then(r => {
                r.map(a => {
                    setCurrent(a.items[0])
                })
            })
        }
    }, [id, token])

    const setAvatar = async (form: FieldValues, articleId: string) => {
        if (!token) {
            return
        }
        if (form.avatar && form.avatar.length > 0) {
            const res = await articleService.setAvatar(token, articleId, form.avatar)
            res.mapError(e => {
                setError(e.error)
            })
        }
    }

    const onSubmit = (form: FieldValues) => {
        if (!token) {
            return
        }
        setLoading(true)
        const request: ArticleRequest = {
            id: current?.id,
            title: form.title,
            body,
            language: form.language,
            tags: [],
            description: form.description,
            externalUrl: form.externalUrl
        }
        if (form.tags) {
            const arr: string[] = form.tags.split(',')
            arr.forEach(t => {
                request.tags?.push(t.trim().toLowerCase())
            })
        }
        if (!current) {
            void articleService.create(token, request).then(res => {
                res
                // eslint-disable-next-line array-callback-return
                    .map(r => {
                        void setAvatar(form, r.id).then(() => {
                            setLoading(false)
                            props.onCancel()
                        })
                    })
                    .mapError(e => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        } else {
            void articleService.update(token, request).then(res => {
                res
                // eslint-disable-next-line array-callback-return
                    .map(r => {
                        void setAvatar(form, r.id).then(() => {
                            setLoading(false)
                            props.onCancel()
                        })
                    })
                    .mapError(e => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        }
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }
        const file = e.target.files[0]
        const { name } = file
        setAvatarName(name)
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            {(!id || current) && <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('Title')}
                        error={errors.title !== undefined}
                        helperText={errors.title !== undefined ? 'Title is required' : ''}
                        defaultValue={current?.title}
                        {...register('title', { required: true })}/>

                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('Description')}
                        error={errors.title !== undefined}
                        defaultValue={current?.description}
                        {...register('description')}/>

                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextEditor text={body} onChange={(text) => {
                        setBody(text)
                    }}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('External url')}
                        error={errors.title !== undefined}
                        defaultValue={current?.externalUrl}
                        {...register('externalUrl')}/>

                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <TextField fullWidth={true}
                        disabled={loading}
                        label={t('Tags')}
                        defaultValue={current?.tags.join(',')}
                        {...register('tags')}/>
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Grid container>
                        <Button
                            variant="outlined"
                            component="label"
                            disabled={loading}
                        >
                            <Trans t={t}>
                                Upload image for avatar
                            </Trans>
                            <input
                                type="file"
                                accept="image/svg+xml"
                                hidden
                                {...register('avatar', { onChange: handleFileUpload })}
                            />
                        </Button>
                        <Box style={{ padding: '10px ' }}>
                            <Typography variant="body1">
                                {avatarName}
                            </Typography>
                        </Box>
                        {current?.avatar && <Box>
                            <img alt="Avatar" src={current?.avatar} width="32" />
                        </Box>}
                    </Grid>
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <InputLabel id="language-label">Language</InputLabel>
                    <Select
                        labelId="language-label"
                        label="Language"
                        disabled={loading}
                        defaultValue={current?.language}
                        {...register('language')}
                    >
                        {languages?.map(v => {
                            return <MenuItem key={v.key} value={v.key}>
                                {v.name}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                          Save
                        </Trans>
                    </Button>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                          Cancel
                        </Trans>
                    </Button>
                </FormControl>
            </Box>}
        </div>
    )
}
