import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

interface DeleteButtonProps {
    header: string
    confirmationText: string
    handleDelete: () => void
}

export const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false)

    const handleClose = () => {
        setConfirmOpen(false)
    }

    return (
        <React.Fragment>
            <IconButton onClick={() => {
                setConfirmOpen(true)
            }}>
                <DeleteForeverIcon />
            </IconButton>
            <Dialog open={confirmOpen} onClose={handleClose}>
                <DialogTitle>
                    {props.header}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.confirmationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={() => {
                        props.handleDelete()
                        setConfirmOpen(false)
                    }} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
