import React, { useEffect, useState } from 'react'
import { StepType } from '../../../services/models'
import { Autocomplete, TextField } from '@mui/material'
import { Control, ControllerRenderProps, FieldError } from 'react-hook-form/dist/types'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { Controller, FieldValues } from 'react-hook-form'

interface StepTypeSelectorSelectorProps {
    label: string
    name: string
    required: boolean
    disabled?: boolean
    multiple?: boolean
    fullWidth?: boolean
    value?: StepType | StepType[]
    control?: Control
    setValue?: UseFormSetValue<FieldValues>
}

export const StepTypeSelector: React.FC<StepTypeSelectorSelectorProps> = (props) => {
    const [values, setValues] = useState<StepType | StepType[] | null>(props.value ?? [])

    useEffect(() => {
        setValues(props.value ?? null)
        if (props.setValue) {
            props.setValue(props.name, props.value)
        }
    }, [])

    const types = [StepType.Text, StepType.Video, StepType.Audio, StepType.Form]

    const select = (field: ControllerRenderProps<FieldValues, string>, error?: FieldError | undefined) => {
        return (
            <Autocomplete
                {...field}
                disablePortal
                multiple={props.multiple}
                fullWidth={props.fullWidth}
                options={types}
                getOptionLabel={(option: StepType) => {
                    return StepType[option]
                }}
                disabled={props.disabled}
                value={values}
                onChange={(_event, value) => {
                    setValues(value)
                    if (props.setValue) {
                        let res: Array<number | null>
                        const isArray = Array.isArray(value)
                        if (!isArray) {
                            res = [value]
                        } else {
                            res = value
                        }
                        props.setValue(props.name, !isArray ? res[0] : res)
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={!!error}
                        helperText={error ? error.message : null}
                        label={props.label}
                    />
                )}
            />
        )
    }

    return (
        <React.Fragment>
            {props.control && (
                <Controller
                    name={props.name}
                    rules={props.required ? { required: 'Select status' } : {}}
                    control={props.control}
                    render={({ field, fieldState: { error } }) => select(field, error)}
                />
            )}
            {!props.control &&
                select({
                    onBlur: () => {},
                    onChange: () => {},
                    name: props.name,
                    value: props.value,
                    ref: () => {}
                })}
        </React.Fragment>
    )
}
