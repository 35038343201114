import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { List } from './List'
import { Edit } from './Edit'
import { Article } from '../../services/models'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

const articleRoutes = {
    list: '/articles/list',
    create: '/articles/create',
    edit: '/articles/edit'
}

export const Articles: React.FC = () => {
    const [currentArticle, setCurrentArticle] = useState<Article>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const editPage = (article?: Article) => {
        setCurrentArticle(article)
        navigate(article ? `${articleRoutes.edit}/${article?.id}` : articleRoutes.create)
    }

    const pagesMap = () => [
        {
            key: '/list',
            page: <List
                onCreate={(a) => editPage(a)}
                canDelete={true}
            />,
            title: t('Articles')
        },
        {
            key: '/create',
            page: <Edit
                onCancel={() => {
                    navigate(articleRoutes.list)
                }}
                article={currentArticle}/>,
            title: t('Create new article')
        },
        {
            key: '/edit/:id',
            page: <Edit
                onCancel={() => {
                    navigate(articleRoutes.list)
                }}
                article={currentArticle}/>,
            title: currentArticle ? currentArticle.title : t('Create new article')
        }
    ]

    const pages = pagesMap()

    return (
        <div style={{ minHeight: '400px' }}>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>
                        {pages.find(p => p.key === location.pathname)?.title}
                    </Trans>
                </Typography>
            </div>
            <Routes>
                {pages.map((m, i) => {
                    return <Route path={m.key} element={m.page} key={i}/>
                })}
            </Routes>
        </div>
    )
}
