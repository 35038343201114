import React, { useState } from 'react'
import { ParticipantRequest } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { List } from './List'
import { View } from './View'

const participantRequestsRoutes = {
    list: '/participants/list',
    view: '/participants/view'
}

export const Requests: React.FC = () => {
    const [currentRequest, setCurrentRequest] = useState<ParticipantRequest>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const viewPage = (request?: ParticipantRequest) => {
        setCurrentRequest(request)
        navigate(participantRequestsRoutes.view)
    }

    const pages = [
        {
            key: '/list',
            page: <List
                onCreate={(a) => viewPage(a)}
                canDelete={true}
            />,
            title: t('Participant requests')
        }]

    if (currentRequest) {
        pages.push(
            {
                key: '/view',
                page: <View
                    onCancel={() => {
                        navigate(participantRequestsRoutes.list)
                    }}
                    request={currentRequest}/>,
                title: currentRequest.name
            })
    }

    return (
        <div style={{ minHeight: '400px' }}>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>
                        {pages.find(p => p.key === location.pathname)?.title}
                    </Trans>
                </Typography>
            </div>
            <Routes>
                {pages.map((m, i) => {
                    return <Route path={m.key} element={m.page} key={i}/>
                })}
            </Routes>
        </div>
    )
}
