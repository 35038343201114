import React from 'react'
import { Account, ListProps } from '../../services/models'
import { useTranslation } from 'react-i18next'
import { defaultPageSize } from '../utils/grid-utils'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel'
import { Alert, Grid, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useUsers } from '../shared/hooks/use-users'
import { DeleteUserButton } from './buttons/DeleteUserButton'

export const List: React.FC<ListProps<Account>> = (props) => {
    const { data, loading, error, setCurrentPage, setSort, refresh } = useUsers()
    const [t] = useTranslation()

    const columns: GridColDef[] = [
        { field: 'firstName', headerName: t('First name'), flex: 1, valueGetter: (r) => r.row.profile.firstName },
        { field: 'surname', headerName: t('Last name'), flex: 2, valueGetter: (r) => r.row.profile.surname },
        {
            field: 'login',
            headerName: t('Login'),
            flex: 1,
            type: 'string',
            valueGetter: (r) => r.row.user.login
        },
        { field: 'email', headerName: t('Email'), flex: 2, valueGetter: r => r.row.user.email },
        {
            field: 'action',
            headerName: '',
            width: 100,
            renderCell: r => {
                return <Grid container direction={'row'}>
                    <IconButton onClick={() => {
                        props.onCreate(r.row)
                    }}>
                        <EditIcon />
                    </IconButton>
                    {props.canDelete && <DeleteUserButton userId={r.row.id} callback={async () => {
                        await refresh()
                    }} />}
                </Grid>
            }
        }
    ]

    const onPageChange = (page: number) => {
        setCurrentPage(page)
    }

    const onSortModelChange = (model: GridSortModel) => {
        const column = model.find(m => m.sort !== undefined)
        if (column?.sort) {
            const sort = `${column.field} ${column.sort === 'asc' ? 'Ascending' : 'Descending'}`
            setSort(sort)
        } else {
            setSort(undefined)
        }
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <DataGrid
                autoHeight={true}
                loading={loading}
                rows={data.items}
                rowCount={data.totalRows}
                columns={columns}
                onPageChange={onPageChange}
                sortingMode={'server'}
                onSortModelChange={onSortModelChange}
                pageSize={defaultPageSize}
                rowsPerPageOptions={[5]}
                paginationMode="server"
            />
        </div>
    )
}
