import React from 'react'
import {
    ListProps,
    Member, UpdateMemberRequest
} from '../../services/models'
import { useTranslation } from 'react-i18next'
import { GridCellEditStopParams, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid'
import {
    Grid,
    IconButton
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Filter } from './Filter'
import { SendEmailButton } from './buttons/SendEmailButton'
import { useMembersData } from './hooks/use-members-data'
import { DeleteMemberButton } from './buttons/DeleteMemberButton'
import { Table } from '../shared/Table'
import { CourseCell } from '../shared/cells/CourseCell'
import { useFilterContext } from '../../hooks/FilterContext'

export const List: React.FC<ListProps<Member>> = (props) => {
    const [t] = useTranslation()

    const { filter, setFilter, sort, setSort } = useFilterContext()
    const { data, refresh, loading, error, setCurrentPage, editRow } = useMembersData()

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('Name'), flex: 2, editable: true, type: 'string' },
        {
            field: 'courseTitle',
            headerName: t('Course'),
            editable: true,
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <CourseCell {...params} />
            },
            flex: 2
        },
        { field: 'confirmSent', headerName: t('Sent'), flex: 0, sortable: false, filterable: false, hideable: false, disableColumnMenu: true, type: 'boolean' },
        { field: 'nickname', headerName: t('Email'), flex: 2, editable: true, type: 'string' },
        { field: 'country', headerName: t('Country'), flex: 1, editable: true, type: 'string' },
        { field: 'stressLevel', headerName: t('Stress'), flex: 1, editable: true, type: 'number' },
        { field: 'telegram', headerName: t('Telegram'), flex: 1, editable: true, type: 'string' },
        {
            field: 'creationDate',
            headerName: t('Creation date'),
            flex: 1,
            valueGetter: (r) => {
                const date = r.row.creationDate
                if (!date) {
                    return ''
                }
                return new Date(r.row.creationDate).toLocaleString()
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 100,
            renderCell: r => {
                return <Grid container direction={'row'}>
                    <IconButton onClick={() => {
                        props.onCreate(r.row)
                    }}>
                        <EditIcon />
                    </IconButton>
                    <DeleteMemberButton memberId={r.row.id} callback={async () => {
                        await refresh()
                    }} />
                </Grid>
            }
        }
    ]

    const onCellEditStop = async (params: GridCellEditStopParams) => {
        const request: UpdateMemberRequest = {
            id: params.id.toString(),
            name: params.row.name,
            typeOfContact: params.row.typeOfContact,
            nickname: params.row.nickname,
            telegram: params.row.telegram,
            country: params.row.country,
            stressLevel: params.row.stressLevel,
            physicalSecurity: params.row.physicalSecurity,
            physicalSecurityComment: params.row.physicalSecurityComment,
            goals: params.row.goals,
            manager: params.row.manager,
            status: params.row.status,
            creationDate: params.row.creationDate,
            updateDate: params.row.updateDate,
            course: params.row.course,
            tags: params.row.tags,
            sourceType: params.row.sourceType,
            sourceTypeText: params.row.sourceTypeText
        }
        // @ts-expect-error
        request[params.field] = params.value

        await editRow(request)
    }

    return (
        <Table
            columns={columns}
            error={error}
            loading={loading}
            setCurrentPage={setCurrentPage}
            defaultSort={sort ?? 'creationDate'}
            setSort={setSort}
            data={data}
            setFilter={setFilter}
            selection={true}
            filter={filter}
            filterForm={(setFilter, defaultFilter) => {
                return <Filter filter={defaultFilter} onSubmit={(filter) => setFilter(filter)}/>
            }}
            actions={(selectionModel, loading) => {
                return (
                    <SendEmailButton disabled={loading} selectionModel={selectionModel} />
                )
            }}
            onCellEditStop={onCellEditStop}
        />
    )
}
