import { ITemplateService } from '../template-service'
import config from '../../config.json'
import { del, get, post, postFile, put } from '../connector'
import { GridData, OperationStatus, Template, TemplateFilter, TemplateRequest } from '../models'

export class TemplateService implements ITemplateService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_CONTENT_SERVICE_API_HOST ??
            config.servers.contentServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'CONTENT_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    find = async (token: string, filter?: TemplateFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}template/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<Template>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    create = async (token: string, request: TemplateRequest) => {
        const url = `${this.apiUrl}template`
        return await post<TemplateRequest, OperationStatus>(token, url, request)
    }

    update = async (token: string, request: TemplateRequest) => {
        const url = `${this.apiUrl}template`
        return await put<TemplateRequest, OperationStatus>(token, url, request)
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}template`
        return await del<OperationStatus>(token, url, { id })
    }

    setAttachments = async (token: string, templateId: string, files: FileList) => {
        const url = `${this.apiUrl}template/attachments?templateId=${templateId}`
        return await postFile<OperationStatus>(token, url, 'template', files)
    }
}
