import {
    Avatar, Grid, IconButton, Tooltip
} from '@mui/material'
import React from 'react'
import PeopleIcon from '@mui/icons-material/People'
import ArticleIcon from '@mui/icons-material/Article'
import SchoolIcon from '@mui/icons-material/School'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import SubjectIcon from '@mui/icons-material/Subject'
import MemoryIcon from '@mui/icons-material/Memory'
import CableIcon from '@mui/icons-material/Cable'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export const MainMenu: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const getBgColor = (selected: boolean) => {
        return selected ? {} : { bgcolor: '#FFFFFF' }
    }

    return (
        <Grid container style={{ position: 'fixed' }}>
            <IconButton disabled={location.pathname.startsWith('/users')} onClick={() => {
                navigate('/users/list')
            }}>
                <Tooltip title={t('Users')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/users'))}>
                        <PeopleIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/templates')} onClick={() => {
                navigate('/templates/list')
            }}>
                <Tooltip title={t('Templates')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/templates'))}>
                        <SubjectIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/articles')} onClick={() => {
                navigate('/articles/list')
            }}>
                <Tooltip title={t('Articles')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/articles'))}>
                        <ArticleIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/courses')} onClick={() => {
                navigate('/courses/list')
            }}>
                <Tooltip title={t('Courses')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/courses'))}>
                        <SchoolIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/participants')} onClick={() => {
                navigate('/participants/list')
            }}>
                <Tooltip title={t('Requests')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/participants'))}>
                        <AddAlertIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/members')} onClick={() => {
                navigate('/members/list')
            }}>
                <Tooltip title={t('Members')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/members'))}>
                        <EmojiPeopleIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/process-steps')} onClick={() => {
                navigate('/process-steps/list')
            }}>
                <Tooltip title={t('Steps')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/process-steps'))}>
                        <CableIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
            <IconButton disabled={location.pathname.startsWith('/processes')} onClick={() => {
                navigate('/processes/list')
            }}>
                <Tooltip title={t('Processes')}>
                    <Avatar sx={getBgColor(location.pathname.startsWith('/processes'))}>
                        <MemoryIcon color={'warning'}/>
                    </Avatar>
                </Tooltip>
            </IconButton>
        </Grid>
    )
}
