import config from '../../config.json'
import { del, get, post, put } from '../connector'
import {
    Account,
    ConfirmUserRequest,
    GridData,
    OperationStatus,
    RegisterRequest,
    UpdateProfileRequest,
    UserFilter
} from '../models'
import { IUsersService, LoginRequest, LoginResult } from '../users-service'

export class UsersService implements IUsersService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_USERS_SERVICE_API_HOST ??
            config.servers.usersServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'USERS_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    login = async (login: string, password: string, captchaToken: string) => {
        const url = `${this.apiUrl}account/login`
        return await post<LoginRequest, LoginResult>(undefined, url, {
            login,
            password,
            captcha: captchaToken
        })
    }

    register = async (request: RegisterRequest) => {
        const url = `${this.apiUrl}account/register`
        return await post<RegisterRequest, OperationStatus>(undefined, url, request)
    }

    find = async (token: string, filter?: UserFilter, limit?: number, skip?: number, sort?: string) => {
        const url = `${this.apiUrl}user/find`
        const data = {
            limit,
            skip,
            sort
        }
        return await get<GridData<Account>>(token, url, filter ? Object.assign(data, filter) : data)
    }

    updateProfile = async (token: string, request: UpdateProfileRequest) => {
        const url = `${this.apiUrl}user/profile`
        return await put<UpdateProfileRequest, OperationStatus>(token, url, request)
    }

    confirm = async (token: string, request: ConfirmUserRequest) => {
        const url = `${this.apiUrl}user/confirm/email`
        return await post<ConfirmUserRequest, OperationStatus>(token, url, request)
    }

    getDescription = (userInfo?: Account) => {
        if (!userInfo) {
            return ''
        }

        if (userInfo.profile.surname && userInfo.profile.firstName) {
            return `${userInfo.profile.surname} ${userInfo.profile.firstName.substring(0, 1)}`
        }

        return userInfo.user.userName
    }

    delete = async (token: string, id: string) => {
        const url = `${this.apiUrl}user`
        return await del<OperationStatus>(token, url, { id })
    }
}
