import { Member, SourceType, UpdateMemberRequest } from '../../services/models'
import React, { useEffect, useState } from 'react'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Alert, Box, Button, FormControl, TextField } from '@mui/material'
import { UserSelector } from '../shared/UserSelector'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RecordStatusSelector } from '../shared/RecordStatusSelector'
import { useMemberService } from '../../hooks/use-member-service'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { CourseSelector } from '../shared/CourseSelector'
import { useParams } from 'react-router-dom'
import { HistoryMemberButton } from './buttons/HistoryMemberButton'
import { SourceTypeSelector } from './controls/SourceTypeSelector'

interface ViewProps {
    member?: Member
    onCancel: () => void
}

export const Edit: React.FC<ViewProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        handleSubmit,
        control,
        setValue
    } = useForm()

    const [t] = useTranslation()
    const { id } = useParams()
    const [memberService] = useMemberService()
    const [error, setError] = useState<string>()
    const [current, setCurrent] = useState(props.member)
    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
        if (!token) {
            return
        }
        if (id && !props.member) {
            void memberService.find(token, { id: [id] }).then(r => {
                r.map(a => {
                    setCurrent(a.items[0])
                })
            })
        }
    }, [id, token])

    const onSubmit = async (form: FieldValues) => {
        if (!token || !current) {
            return
        }
        setSaving(true)
        const request: UpdateMemberRequest = {
            id: current.id,
            name: form.name,
            typeOfContact: form.typeOfContact,
            nickname: form.nickname,
            telegram: form.telegram,
            country: form.country,
            stressLevel: form.stressLevel,
            physicalSecurity: form.physicalSecurity,
            physicalSecurityComment: form.physicalSecurityComment,
            goals: form.goals,
            manager: form.manager,
            status: form.status,
            creationDate: current.creationDate,
            updateDate: current.updateDate,
            course: form.course,
            tags: form.tags,
            sourceType: SourceType[Number(form.sourceType)],
            sourceTypeText: form.sourceTypeText
        }
        const result = await memberService.update(token, request)
        result.map(status => {
            if (!status.succeeded) {
                setError('Error in updating member!')
            } else {
                props.onCancel()
            }
        }).mapError(e => setError(e.error))

        setSaving(false)
    }

    const creationDate = dayjs(current?.creationDate)
    const updateDate = current?.updateDate ? dayjs(current?.updateDate) : null

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {error && <Alert severity="error">{error}</Alert>}
            {(!id || current) && <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                {current && <FormControl sx={{ m: 1 }}>
                    <HistoryMemberButton memberId={current.id} />
                </FormControl>}
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'name'}
                        control={control}
                        defaultValue={current?.name}
                        rules={{ required: 'Name is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                disabled={saving}
                                error={!!error}
                                helperText={error ? error.message : null}
                                label={'Name'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <CourseSelector name={'course'}
                        label={'Course'}
                        values={current?.course}
                        required={true}
                        control={control}
                        setValue={setValue}
                        disabled={saving}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Controller
                        name={'typeOfContact'}
                        control={control}
                        defaultValue={current?.typeOfContact}
                        rules={{ required: 'Type is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled={saving}
                                InputProps={{
                                    readOnly: true
                                }}
                                label={'Type'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Controller
                        name={'nickname'}
                        control={control}
                        defaultValue={current?.nickname}
                        rules={{ required: 'Nickname is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled={saving}
                                label={'Nickname'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Controller
                        name={'telegram'}
                        control={control}
                        defaultValue={current?.telegram}
                        rules={{ required: 'Telegram is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled={saving}
                                label={'Telegram'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Controller
                        name={'country'}
                        control={control}
                        defaultValue={current?.country}
                        rules={{ required: 'Country is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled={saving}
                                label={'Country'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Controller
                        name={'stressLevel'}
                        control={control}
                        defaultValue={current?.stressLevel}
                        render={({ field }) => (
                            <TextField {...field}
                                color={current?.stressLevel ?? 0 > 6 ? 'error' : (current?.stressLevel ?? 0 > 3 ? 'warning' : 'success')}
                                type={'number'}
                                disabled={saving}
                                InputProps={{
                                    readOnly: true
                                }}
                                focused
                                label={'Stress level'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Controller
                        name={'physicalSecurity'}
                        control={control}
                        defaultValue={current?.physicalSecurity}
                        render={({ field }) => (
                            <TextField {...field}
                                color={current?.physicalSecurity ? 'success' : 'error'}
                                disabled={saving}
                                InputProps={{
                                    readOnly: true
                                }}
                                focused
                                label={t('Physical security')} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'physicalSecurityComment'}
                        control={control}
                        defaultValue={current?.physicalSecurityComment}
                        render={({ field }) => (
                            <TextField {...field}
                                multiline={true}
                                rows={2}
                                disabled={saving}
                                InputProps={{
                                    readOnly: true
                                }}
                                label={t('Physical security comment')} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'goals'}
                        control={control}
                        defaultValue={current?.goals}
                        rules={{ required: 'Goals is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                multiline={true}
                                rows={3}
                                disabled={saving}
                                InputProps={{
                                    readOnly: true
                                }}
                                label={t('Goals')} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <UserSelector
                        name={'manager'}
                        disabled={saving}
                        control={control}
                        setValue={setValue}
                        values={current?.manager}
                        label={'Manager'}
                        required={false}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <DateTimePicker
                        label={t('Creation date')}
                        value={creationDate}
                        disabled={saving}
                        readOnly={true}
                        onChange={() => {}}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <DateTimePicker
                        label={t('Update date')}
                        value={updateDate}
                        disabled={saving}
                        readOnly={true}
                        onChange={() => {}}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }} style={{ width: '200px' }}>
                    <SourceTypeSelector
                        label={t('Source type')}
                        required={true}
                        name="sourceType"
                        disabled={saving}
                        control={control}
                        setValue={setValue}
                        value={Number(current?.sourceType)}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }} style={{ width: '400px' }}>
                    <Controller
                        name={'sourceTypeText'}
                        control={control}
                        defaultValue={current?.sourceTypeText}
                        render={({ field }) => (
                            <TextField {...field}
                                disabled={saving}
                                label={'Source type text'} />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }} style={{ width: '200px' }}>
                    <RecordStatusSelector
                        label={t('Status')}
                        required={true}
                        name="status"
                        disabled={saving}
                        control={control}
                        setValue={setValue}
                        value={current?.status}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'tags'}
                        control={control}
                        defaultValue={current?.tags}
                        render={({ field }) => (
                            <TextField {...field}
                                label={t('Tags')}
                                disabled={saving}
                            />
                        )}
                    />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={saving} variant="contained" type="submit">
                        <Trans t={t}>
                            Save
                        </Trans>
                    </Button>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={saving} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                            Cancel
                        </Trans>
                    </Button>
                </FormControl>
            </Box>}
        </LocalizationProvider>
    )
}
