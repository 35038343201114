import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUsersService } from '../../../hooks/use-users-service'
import { DeleteButton } from '../../shared/DeleteButton'
import { Alert } from '@mui/material'

interface DeleteUserButtonProps {
    userId: string
    callback: () => void
}
export const DeleteUserButton: React.FC<DeleteUserButtonProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [error, setError] = useState<string>()
    const [usersService] = useUsersService()
    const [t] = useTranslation()

    const handleDelete = () => {
        if (!token || !props.userId) {
            return
        }
        void usersService.delete(token, props.userId)
            .then(r => {
                r.map(rr => {
                    props.callback()
                }).mapError(e => setError(e.error))
            })
    }

    return (
        <React.Fragment>
            <DeleteButton
                confirmationText={t('Are you sure you want to remove user?')}
                header={t('Delete user')}
                handleDelete={handleDelete}
            />
            {error && <Alert severity="error">{error}</Alert>}
        </React.Fragment>
    )
}
