import React, { useState } from 'react'
import { Member, RecordStatus } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { List } from './List'
import { Edit } from './Edit'
import { FilterContextProvider } from '../../hooks/FilterContext'

const membersRoutes = {
    list: '/members/list',
    edit: '/members/edit'
}

export const Members: React.FC = () => {
    const [currentMember, setCurrentMember] = useState<Member>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const viewPage = (member?: Member) => {
        setCurrentMember(member)
        navigate(`${membersRoutes.edit}/${member?.id}`)
    }

    const pages = [
        {
            key: '/list',
            page: <List
                onCreate={(a) => viewPage(a)}
                canDelete={true}
            />,
            title: t('Members')
        }]

    pages.push(
        {
            key: '/edit/:id',
            page: <Edit
                onCancel={() => {
                    navigate(membersRoutes.list)
                }}
                member={currentMember}/>,
            title: currentMember?.name ?? t('Member')
        })

    return (
        <div style={{ minHeight: '400px' }}>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>
                        {pages.find(p => p.key === location.pathname)?.title}
                    </Trans>
                </Typography>
            </div>
            <FilterContextProvider filter={{ status: [RecordStatus.New] }}>
                <Routes>
                    {pages.map((m, i) => {
                        return <Route path={m.key} element={m.page} key={i}/>
                    })}
                </Routes>
            </FilterContextProvider>
        </div>
    )
}
