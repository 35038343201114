import React from 'react'
import { ListProps, ParticipantRequest, RecordStatus } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import {
    Button,
    Grid,
    IconButton
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useParticipantsRequests } from './hooks/use-participants-requests'
import { DeleteParticipantRequestButton } from './buttons/DeleteParticipantRequestButton'
import { Table } from '../shared/Table'

export const List: React.FC<ListProps<ParticipantRequest>> = (props) => {
    const { data, loading, error, refresh, setSort, setCurrentPage } = useParticipantsRequests()
    const [t] = useTranslation()

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('Name'), flex: 2 },
        { field: 'typeOfContact', headerName: t('Type'), flex: 1 },
        { field: 'nickname', headerName: t('Nickname'), flex: 1 },
        { field: 'manager', headerName: t('Manager'), flex: 1 },
        {
            field: 'creationDate',
            headerName: t('Creation date'),
            flex: 1,
            valueGetter: (r) => {
                const date = r.row.creationDate
                if (!date) {
                    return ''
                }
                return new Date(r.row.creationDate).toLocaleString()
            }
        },
        {
            field: 'status',
            headerName: t('Status'),
            flex: 1,
            valueGetter: (r) => {
                return RecordStatus[r.row.status]
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 100,
            renderCell: r => {
                return <Grid container direction={'row'}>
                    <IconButton onClick={() => {
                        props.onCreate(r.row)
                    }}>
                        <EditIcon />
                    </IconButton>
                    {props.canDelete && <DeleteParticipantRequestButton id={r.row.id} callback={async () => {
                        await refresh()
                    }} />}
                </Grid>
            }
        }
    ]
    return (
        <Table
            columns={columns}
            error={error}
            loading={loading}
            setCurrentPage={setCurrentPage}
            defaultSort={'creationDate'}
            setSort={setSort}
            data={data}
            selection={true}
            actions={(selectionModel, loading) => {
                return (
                    <Button variant="contained" onClick={() => props.onCreate()}>
                        <Trans t={t}>
                            Create
                        </Trans>
                    </Button>
                )
            }}
        />
    )
}
