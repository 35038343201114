import React, { useEffect, useState } from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import { Control, ControllerRenderProps, FieldError } from 'react-hook-form/dist/types'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { Autocomplete, Box, BoxProps, Button, Card, Grid, TextField, Typography } from '@mui/material'
import { StepTemplate } from '../../../services/models'
import { useStepTemplateData } from '../hooks/use-step-template-data'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

interface StepSelectorProps {
    label: string
    name: string
    required: boolean
    disabled?: boolean
    fullWidth?: boolean
    multiple?: boolean
    values?: string | string[]
    control?: Control
    setValue?: UseFormSetValue<FieldValues>
    table?: boolean
}

export const StepSelector: React.FC<StepSelectorProps> = (props) => {
    const [values, setValues] = useState<StepTemplate | StepTemplate[] | null>([])
    const { data, getTemplate, loading } = useStepTemplateData()

    useEffect(() => {
        if (props.values) {
            void getTemplate(props.values).then(r => {
                if (r) {
                    const dict = r.reduce((p: any, c) => {
                        p[c.id] = c
                        return p
                    }, {})
                    const sorted: StepTemplate[] | StepTemplate = Array.isArray(props.values) ? props.values.map(p => dict[p]) : dict[0]
                    setValues(sorted)
                    if (props.setValue) {
                        props.setValue(props.name, Array.isArray(sorted) ? sorted.map(c => c?.id) : sorted?.id)
                    }
                }
            })
        }
    }, [])

    const select = (field: ControllerRenderProps<FieldValues, string>, error?: FieldError | undefined) => {
        return (
            <Autocomplete
                {...field}
                disablePortal
                disabled={loading || props.disabled}
                multiple={props.multiple}
                fullWidth={props.fullWidth}
                options={data?.items || []}
                getOptionLabel={(option: StepTemplate) => {
                    return `${option.name}(${option.language})`
                }}
                value={values}
                isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id
                }}
                onChange={(_event, value) => {
                    setValues(value)
                    if (props.setValue) {
                        let res: Array<StepTemplate | null> = []
                        const isArray = Array.isArray(value)
                        if (!isArray) {
                            res = [value]
                        } else {
                            res = value
                        }
                        props.setValue(props.name, !isArray ? res[0]?.id : res.map(c => c?.id))
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params} error={!!error} helperText={error ? error.message : null} label={props.label} />
                )}
            />
        )
    }

    const up = (template: StepTemplate | null) => {
        if (!template) {
            return
        }
        if (Array.isArray(values)) {
            const rows = [...values]
            const index = rows.indexOf(template)
            rows[index] = rows[index - 1]
            rows[index - 1] = template
            setValues(rows)
            if (props.setValue) {
                props.setValue(props.name, rows.map(c => c?.id))
            }
        }
    }

    const down = (template: StepTemplate | null) => {
        if (!template) {
            return
        }
        if (Array.isArray(values)) {
            const rows = [...values]
            const index = rows.indexOf(template)
            rows[index] = rows[index + 1]
            rows[index + 1] = template
            setValues(rows)
            if (props.setValue) {
                props.setValue(props.name, rows.map(c => c?.id))
            }
        }
    }

    const del = (template: StepTemplate | null) => {
        if (!template) {
            return
        }
        if (Array.isArray(values)) {
            const rows = [...values]
            const index = rows.indexOf(template)
            rows.splice(index, 1)
            setValues(rows)
            if (props.setValue) {
                props.setValue(props.name, rows.map(c => c?.id))
            }
        }
    }

    const Item = (props: BoxProps) => {
        const { sx, ...other } = props
        return (
            <Box
                sx={{
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    p: 1,
                    m: 1,
                    borderRadius: 2,
                    padding: 1,
                    ...sx
                }}
                {...other}
            />
        )
    }

    const table = () => {
        const rows = Array.isArray(values) ? values : [values]
        return (
            <Card variant="outlined">
                <Grid container direction={'column'}>
                    {rows.map((r, i) => (
                        <Grid style={{ border: '1px' }} key={i} item container direction={'row'}>
                            <Item width="50%">
                                <Box style={{ padding: 5 }}>
                                    <Typography variant={'body1'}>{r?.name}</Typography>
                                </Box>
                            </Item>
                            <Item>
                                <Box style={{ padding: 5 }}>
                                    <Typography variant={'body1'}>{r?.language}</Typography>
                                </Box>
                            </Item>
                            <Item>
                                <Button variant={'text'} disabled={loading} onClick={() => del(r)}>
                                    <HighlightOffIcon width={24} height={24} />
                                </Button>
                            </Item>
                            {i > 0 && <Item>
                                <Button variant="text" disabled={loading} onClick={() => up(r)}>
                                    <ArrowCircleUpIcon width={24} height={24} />
                                </Button>
                            </Item>}
                            {i < rows.length - 1 && <Item>
                                <Button variant="text" disabled={loading} onClick={() => down(r)}>
                                    <ArrowCircleDownIcon width={24} height={24} />
                                </Button>
                            </Item>}
                        </Grid>
                    ))}
                    <Grid item style={{ padding: 10 }}>
                        <Autocomplete
                            disablePortal
                            disabled={loading || props.disabled}
                            fullWidth={props.fullWidth}
                            options={data?.items || []}
                            getOptionLabel={(option: StepTemplate) => {
                                return `${option.name}(${option.language})`
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option?.id === value?.id
                            }}
                            value={null}
                            blurOnSelect={true}
                            onChange={(_event, value) => {
                                if (value) {
                                    const rows = Array.isArray(values) ? values : [values]
                                    // @ts-expect-error
                                    setValues([...rows, value])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                    </Grid>
                </Grid>
            </Card>
        )
    }

    return (
        <React.Fragment>
            {props.table && table()}
            {!props.table && props.control && (
                <Controller
                    name={props.name}
                    rules={props.required ? { required: 'Select steps' } : {}}
                    control={props.control}
                    render={({ field, fieldState: { error } }) => select(field, error)}
                />
            )}
            {!props.table && !props.control &&
                select({
                    onBlur: () => {},
                    onChange: () => {},
                    name: props.name,
                    value: props.values,
                    ref: () => {}
                })}
        </React.Fragment>
    )
}
