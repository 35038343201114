import React, { Dispatch, SetStateAction } from 'react'
import { Entity, Filter, GridData } from '../../services/models'
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel'
import { Alert, Grid } from '@mui/material'
import { DataGrid, GridColDef, GridRowIdGetter, GridSelectionModel } from '@mui/x-data-grid'
import { defaultPageSize } from '../utils/grid-utils'
import { GridEventListener } from '@mui/x-data-grid/models/events'

interface TableProps {
    columns: GridColDef[]
    error: string | undefined
    loading: boolean
    setCurrentPage: (page: number) => void
    defaultSort: string
    setSort: Dispatch<SetStateAction<string | undefined>> | undefined
    data: GridData<Entity>
    setFilter?: Dispatch<SetStateAction<Filter | undefined>>
    filter?: Filter | undefined
    selection: boolean
    filterForm?: (setFilter: Dispatch<SetStateAction<Filter | undefined>>, defaultFilter: Filter | undefined) => React.ReactElement
    actions?: (selectionModel: GridSelectionModel, loading: boolean) => React.ReactElement
    getRowId?: GridRowIdGetter<any>
    onCellEditStop?: GridEventListener<'cellEditStop'>
}

export const Table: React.FC<TableProps> = (props) => {
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([])

    const onPageChange = (page: number) => {
        props.setCurrentPage(page)
    }

    const onSortModelChange = (model: GridSortModel) => {
        if (!props.setSort) {
            return
        }
        const column = model.find(m => m.sort !== undefined)
        if (column?.sort) {
            const sort = `${column.field} ${column.sort === 'asc' ? 'Ascending' : 'Descending'}`
            props.setSort(sort)
        } else {
            props.setSort(undefined)
        }
    }

    const getRowId: GridRowIdGetter<any> = (row) => row.id

    const sortArr = props.defaultSort?.split(' ')
    const defaultSortDirection = sortArr[1] === 'Ascending' ? 'asc' : 'desc'

    return (
        <div>
            {(props.error != null) && <Alert severity="error">{props.error}</Alert>}
            {props.filterForm && props.setFilter && props.filterForm(props.setFilter, props.filter)}
            {props.actions && <Grid container gap={2} style={{ paddingBottom: '10px' }}>
                {props.actions(selectionModel, props.loading)}
            </Grid>}
            <DataGrid
                getRowId={props.getRowId ?? getRowId}
                checkboxSelection={props.selection}
                keepNonExistentRowsSelected={props.selection}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                selectionModel={selectionModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: sortArr[0], sort: defaultSortDirection ?? 'desc' }]
                    }
                }}
                loading={props.loading}
                autoHeight={true}
                rows={props.data.items}
                columns={props.columns}
                onPageChange={onPageChange}
                sortingMode={'server'}
                onSortModelChange={onSortModelChange}
                rowCount={props.data.totalRows}
                pageSize={defaultPageSize}
                rowsPerPageOptions={[5]}
                paginationMode="server"
                onCellEditCommit={props.onCellEditStop}
                disableSelectionOnClick={true}
            />
        </div>
    )
}
