import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMembersHistory } from './hooks/use-members-history'
import { Table } from '../shared/Table'
import { GridColDef } from '@mui/x-data-grid'
import { HistoryFilter } from '../shared/HistoryFilter'
import { HistoryEventType } from '../../services/models'

interface HistoryProps {
    objectId: string
}

export const History: React.FC<HistoryProps> = (props) => {
    const [t] = useTranslation()

    const { data, loading, error, setFilter, filter, setCurrentPage, setSort } = useMembersHistory(props.objectId)

    const columns: GridColDef[] = [
        {
            field: 'timestamp',
            headerName: t('Date'),
            flex: 2,
            valueGetter: (r) => {
                const date = r.value
                if (!date) {
                    return ''
                }
                return new Date(r.value).toLocaleString()
            }
        },
        {
            field: 'event',
            headerName: t('Event'),
            flex: 1,
            valueGetter: (r) => {
                return HistoryEventType[r.value]
            }
        },
        { field: 'authorId', headerName: t('Author'), flex: 2 }
    ]

    return (
        <Table
            getRowId={(r) => r.timestamp}
            columns={columns}
            error={error}
            loading={loading}
            setCurrentPage={setCurrentPage}
            defaultSort={'timestamp'}
            setSort={setSort}
            data={data}
            setFilter={setFilter}
            selection={false}
            filterForm={(setFilter) => {
                return <HistoryFilter filter={filter} onSubmit={(filter) => setFilter(filter)}/>
            }}
        />
    )
}
