import { ListProps, ProcessTemplate } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import { Button, Grid, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { DeleteArticleButton } from '../articles/buttons/DeleteArticleButton'
import { Table } from '../shared/Table'
import React from 'react'
import { useProcessTemplateData } from './hooks/use-process-template-data'

export const List: React.FC<ListProps<ProcessTemplate>> = (props) => {
    const { data, loading, setCurrentPage, setSort, error, refresh } = useProcessTemplateData()

    const [t] = useTranslation()

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('Name'), flex: 2 },
        { field: 'authorId', headerName: t('Author'), flex: 1 },
        {
            field: 'creationDate',
            headerName: t('Creation date'),
            flex: 1,
            valueGetter: (r) => {
                const date = r.row.creationDate
                if (!date) {
                    return ''
                }
                return new Date(r.row.creationDate).toLocaleString()
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 100,
            renderCell: (r) => {
                return (
                    <Grid container direction={'row'}>
                        <IconButton
                            onClick={() => {
                                props.onCreate(r.row)
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        {props.canDelete && (
                            <DeleteArticleButton
                                articleId={r.row.id}
                                callback={async () => {
                                    await refresh()
                                }}
                            />
                        )}
                    </Grid>
                )
            }
        }
    ]

    return (
        <Table
            columns={columns}
            error={error}
            loading={loading}
            setCurrentPage={setCurrentPage}
            defaultSort={'creationDate'}
            setSort={setSort}
            data={data}
            selection={true}
            actions={(selectionModel, loading) => {
                return (
                    <Button variant="contained" onClick={() => props.onCreate()}>
                        <Trans t={t}>Create</Trans>
                    </Button>
                )
            }}
        />
    )
}
