import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useTranslation } from 'react-i18next'
import { useArticleService } from '../../../hooks/use-article-service'
import { DeleteButton } from '../../shared/DeleteButton'
import { Alert } from '@mui/material'

interface DeleteArticleButtonProps {
    articleId: string
    callback: () => void
}

export const DeleteArticleButton: React.FC<DeleteArticleButtonProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [error, setError] = useState<string>()
    const [articleService] = useArticleService()
    const [t] = useTranslation()

    const handleDelete = () => {
        if (!token || !props.articleId) {
            return
        }
        void articleService.delete(token, props.articleId)
            .then(r => {
                // eslint-disable-next-line array-callback-return
                r.map(() => {
                    props.callback()
                }).mapError(e => setError(e.error))
            })
    }

    return (
        <React.Fragment>
            <DeleteButton
                confirmationText={t('Are you sure you want to remove course?')}
                header={t('Delete course')}
                handleDelete={handleDelete}
            />
            {error && <Alert severity="error">{error}</Alert>}
        </React.Fragment>
    )
}
