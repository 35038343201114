import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useEffect, useState } from 'react'
import { useUsersService } from '../../../hooks/use-users-service'
import { Account, GridData, UserFilter } from '../../../services/models'
import { defaultPageSize } from '../../utils/grid-utils'

export const useUsers = () => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [filter, setFilter] = useState<UserFilter>()
    const [limit, setLimit] = useState<number>(defaultPageSize)
    const [skip, setSkip] = useState<number>()
    const [sort, setSort] = useState<string | undefined>('creationDate Descending')
    const [data, setData] = useState<GridData<Account>>({ items: [], totalRows: 0 })

    const [userService] = useUsersService()

    const loadUsers = async () => {
        let users: GridData<Account> = { items: [], totalRows: 0 }
        if (!token) {
            setData(users)
            return
        }
        setLoading(true)
        const res = await userService.find(token, filter, limit, skip, sort)
        setLoading(false)
        res.map(r => {
            users = r
        }).mapError(e => {
            setError(e.error)
        })
        setData(users)
    }

    const getUser = async (id?: string | string[]): Promise<Account[] | undefined> => {
        if (!token || !id) {
            return undefined
        }
        const res = await userService.find(token, { user: Array.isArray(id) ? id : [id] })
        // eslint-disable-next-line no-undef-init
        let user: Account[] | undefined = undefined
        res.map(r => {
            user = r.items
        })
        return user
    }

    const setCurrentPage = (page: number) => {
        setSkip(limit * page)
    }

    const refresh = async () => {
        await loadUsers()
    }

    useEffect(() => {
        loadUsers().catch(e => setError(e))
    }, [token, filter, limit, skip, sort])

    return {
        data,
        getUser,
        refresh,
        setFilter,
        setLimit,
        setCurrentPage,
        setSort,
        loading,
        error
    }
}
