import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { SendEmail } from '../dialogs/SendEmail'
import React, { useState } from 'react'
import { GridSelectionModel } from '@mui/x-data-grid'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'

interface SendEmailButtonProps {
    disabled: boolean
    selectionModel: GridSelectionModel
}

export const SendEmailButton: React.FC<SendEmailButtonProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const [sendDialogOpen, setSendDialogOpen] = useState(false)
    const [t] = useTranslation()

    const getSelectedMembers = () => {
        return props.selectionModel.map(v => v.toString())
    }

    const sendEmails = () => {
        if (!token) {
            return
        }
        setSendDialogOpen(true)
    }

    return (
        <React.Fragment>
            <Button disabled={props.disabled} variant="contained" onClick={sendEmails}>
                <Trans t={t}>
                    Send confirmation email
                </Trans>
            </Button>
            <Dialog open={sendDialogOpen} fullWidth={true}>
                <DialogTitle>
                    <Trans t={t}>
                        Send emails
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <SendEmail recipients={getSelectedMembers()} onCancel={() => {
                        setSendDialogOpen(false)
                    }} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
