import { IFileService } from '../file-service'
import { FileInfo, OperationStatus } from '../models'
import { get, postFile } from '../connector'
import config from '../../config.json'

export class FileService implements IFileService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_CONTENT_SERVICE_API_HOST ?? config.servers.contentServiceUrl
        // eslint-disable-next-line no-console
        console.info('CONTENT_SERVICE_API_HOST: Using defaults, result:', this.apiUrl)
    }

    upload = async (token: string, file: FileList) => {
        const url = `${this.apiUrl}file/upload`
        return await postFile<OperationStatus>(token, url, 'upload', file)
    }

    info = async (token: string, id: string) => {
        const url = `${this.apiUrl}file/info/${id}`
        return await get<FileInfo>(token, url)
    }
}
