import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../../App'
import { useTemplateService } from '../../../hooks/use-template-service'
import { SendEmailRequest, Template, TemplateFilter } from '../../../services/models'
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    Link,
    TextField, Typography
} from '@mui/material'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMemberService } from '../../../hooks/use-member-service'

interface SendEmailProps {
    recipients: string[]
    onCancel: () => void
}

export const SendEmail: React.FC<SendEmailProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)

    const [templateService] = useTemplateService()
    const [memberService] = useMemberService()
    const [t] = useTranslation()

    const [templates, setTemplates] = useState<Template[]>([])
    const [currentTemplate, setCurrentTemplate] = useState<Template | null>()
    const [error, setError] = useState<string>()
    const [success, setSuccess] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>()

    const {
        handleSubmit,
        control,
        setValue
    } = useForm()

    useEffect(() => {
        if (!token) {
            return
        }
        const filter: TemplateFilter = {
            tags: ['email']
        }
        setLoading(true)
        void templateService.find(token, filter).then(res => {
            // eslint-disable-next-line array-callback-return
            res.map(tt => {
                setTemplates(tt.items)
            }).mapError(e => {
                setError(e.error)
            })
            setLoading(false)
        })
    }, [token])

    const onSubmit = async (form: FieldValues) => {
        if (!token) {
            setError('Token is empty!')
            return
        }
        if (!form.subject || !form.body) {
            setError('Subject or body not set')
            return
        }
        const request: SendEmailRequest = {
            subject: form.subject,
            body: form.body,
            attachments: currentTemplate?.attachments,
            members: props.recipients
        }
        setLoading(true)
        void memberService.sendEmail(token, request).then(res => {
            // eslint-disable-next-line array-callback-return
            res.map(r => {
                if (!r.succeeded) {
                    setError(r.error)
                } else {
                    setSuccess(true)
                }
            }).mapError(e => {
                setError(e.error)
            })
            setLoading(false)
        })
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Container>
                <FormControl sx={{ m: 1 }} fullWidth={true}>
                    <Typography variant={'h6'}>
                        <Trans t={t}>
                            Messages were sent!
                        </Trans>
                    </Typography>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                            Close
                        </Trans>
                    </Button>
                </FormControl>
            </Container>}
            {!success && <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Autocomplete
                        disablePortal
                        options={templates}
                        disabled={loading}
                        fullWidth={true}
                        getOptionLabel={r => {
                            return `${r.description} (${r.language})`
                        }}
                        onChange={(e, value) => {
                            const template: Template | null = value
                            if (!template) {
                                return
                            }
                            setCurrentTemplate(template)
                            setValue('subject', template.subject)
                            setValue('body', template.body)
                        }}
                        renderInput={(params) => <TextField {...params} label="Templates" />}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'subject'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Subject is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled={loading}
                                label={'Subject'} />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'body'}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Template is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                multiline={true}
                                rows={15}
                                disabled={loading}
                                label={t('Template')}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                </FormControl>
                {currentTemplate?.attachments && <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Grid container gap={1}>
                        {currentTemplate?.attachments.map(a =>
                            <Grid item xs={12} key={a.url}>
                                <Link href={a.url} target={'_blank'}>
                                    {a.name}
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </FormControl>}
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                            Send
                        </Trans>
                    </Button>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                            Cancel
                        </Trans>
                    </Button>
                </FormControl>
            </Box>}
        </div>
    )
}
