import React, { useState } from 'react'
import { HistoryEventType } from '../../services/models'
import { Autocomplete, TextField } from '@mui/material'

interface HistoryEventTypeSelectorProps {
    label: string
    multiselect: boolean
    name: string
    defaultValue?: HistoryEventType[]
    onChange?: (selected: HistoryEventType[]) => void
}
export const HistoryEventTypeSelector: React.FC<HistoryEventTypeSelectorProps> = (props) => {
    const [selectedStatuses, setSelectedStatuses] = useState(props.defaultValue)
    const statuses = Object.keys(HistoryEventType).filter((item) => {
        return !isNaN(Number(item))
    }).map(item => Number(item))

    return (
        <React.Fragment>
            <Autocomplete
                disablePortal
                multiple={true}
                fullWidth={true}
                options={statuses}
                getOptionLabel={(option) => HistoryEventType[option]}
                value={selectedStatuses}
                onChange={(_event, value) => {
                    setSelectedStatuses(value)
                    if (props.onChange) {
                        props.onChange(value)
                    }
                }}
                renderInput={(params) => <TextField {...params} label={props.label} />}
            />
        </React.Fragment>
    )
}
