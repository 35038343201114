import React, { useEffect, useState } from 'react'
import { Account, ConfirmUserRequest, Role, UpdateProfileRequest } from '../../services/models'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { Trans, useTranslation } from 'react-i18next'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useUsersService } from '../../hooks/use-users-service'
import { useParams } from 'react-router-dom'
import { useRoleService } from '../../hooks/use-role-service'

interface EditProps {
    account?: Account
    onCancel: () => void
}

export const Edit: React.FC<EditProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        control,
        handleSubmit,
        setValue
    } = useForm()
    const [usersService] = useUsersService()
    const [roleService] = useRoleService()
    const [t] = useTranslation()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [current, setCurrent] = useState(props.account)
    const [roles, setRoles] = useState<Role[]>()

    useEffect(() => {
        if (!token) {
            return
        }
        if (id && !props.account) {
            void Promise.all([
                usersService.find(token, { user: [id] })
            ]).then(([users]) => {
                users.map(a => {
                    setCurrent(a.items[0])
                })
            })
        }
    }, [id, token])

    useEffect(() => {
        if (!token) {
            return
        }

        void roleService.find(token).then(rr => {
            rr.map(a => {
                setRoles(a.items)
            })
        })
    }, [token])

    const onSubmit = (form: FieldValues) => {
        if (!token || !current?.id) {
            return
        }
        setLoading(true)
        const request: UpdateProfileRequest = {
            userId: current.id,
            firstName: form.firstName,
            surname: form.surname,
            roles: form.roles
        }
        void usersService.updateProfile(token, request).then(res => {
            res
            // eslint-disable-next-line array-callback-return
                .map(r => {
                    if (!r.succeeded) {
                        setError(r.error)
                    } else {
                        props.onCancel()
                    }
                })
                .mapError(e => {
                    setError(e.error)
                })
            setLoading(false)
        })
    }

    const confirm = async () => {
        if (!token || !current?.id) {
            return
        }
        setLoading(true)

        const request: ConfirmUserRequest = {
            userId: current?.id
        }

        const res = await usersService.confirm(token, request)
        res.map(r => {
            if (r.succeeded) {
                setValue('emailConfirmed', true)
            } else {
                setError(r.error)
            }
        }).mapError(e => {
            setError(e.error)
        })

        setLoading(false)
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            <Box
                component="form"
                method="POST"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                {(!id || current) && !current?.user.emailConfirmed && <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="button" onClick={confirm}>
                        <Trans t={t}>
                            Confirm
                        </Trans>
                    </Button>
                </FormControl>}
                {(!id || current) && <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'firstName'}
                        control={control}
                        defaultValue={current?.profile.firstName}
                        rules={{ required: 'Firstname is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                fullWidth={true}
                                disabled={loading}
                                label={t('First name')}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                </FormControl>}
                {(!id || current) && <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'surname'}
                        control={control}
                        defaultValue={current?.profile.surname}
                        rules={{ required: 'Surname is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field}
                                fullWidth={true}
                                disabled={loading}
                                label={t('Surname')}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                </FormControl>}
                {(!id || current) && <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <Controller
                        name={'emailConfirmed'}
                        control={control}
                        defaultValue={current?.user.emailConfirmed}
                        render={({ field }) => (
                            <TextField {...field}
                                fullWidth={true}
                                InputProps={{
                                    readOnly: true
                                }}
                                label={t('Email confirmed')}
                            />
                        )}
                    />
                </FormControl>}
                {(!id || current) && roles && <FormControl fullWidth={true} sx={{ m: 1 }}>
                    <InputLabel id="role-select-label">
                        <Trans t={t}>
                            Roles
                        </Trans>
                    </InputLabel>
                    <Controller
                        name={'roles'}
                        control={control}
                        defaultValue={current?.user.roles ?? []}
                        render={({ field }) => <Select {...field}
                            multiple={true}
                            labelId="role-select-label"
                            label={'Roles'}>
                            {roles.map((r, i) => {
                                return <MenuItem value={r.id} key={`role${i}`}>
                                    {r.name}
                                </MenuItem>
                            })}
                        </Select>}
                    />
                </FormControl>}
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" type="submit">
                        <Trans t={t}>
                            Save
                        </Trans>
                    </Button>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                        <Trans t={t}>
                            Cancel
                        </Trans>
                    </Button>
                </FormControl>
            </Box>
        </div>
    )
}
