import React, { useState } from 'react'
import { Box, Button, Card, CardContent, FormControl, Grid, TextField, Typography } from '@mui/material'
import { useUsersService } from '../hooks/use-users-service'
import { ApiError } from '../services/exceptions'
import { useForm } from 'react-hook-form'
import '../styles/Login.css'
import { Trans, useTranslation } from 'react-i18next'
import { useCaptchaContext } from '../hooks/CaptchaContext'

interface LoginProps {
    onLogin: (token: string) => void
    onError?: (e: ApiError) => void
}

export const Login: React.FC<LoginProps> = (props) => {
    const [posting, setPosting] = useState(false)
    const [userService] = useUsersService()
    const [t] = useTranslation()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()
    const { recaptchaRef } = useCaptchaContext()

    const onSubmit = async (form: any): Promise<void> => {
        if (!recaptchaRef) {
            if (props.onError != null) {
                props.onError({
                    type: 'API_ERROR',
                    error: t('Captcha recaptchaRef not found'),
                    code: 502
                })
            }
            return
        }

        const token = await recaptchaRef.current?.executeAsync()

        if (token == null) {
            if (props.onError != null) {
                props.onError({
                    type: 'API_ERROR',
                    error: t('Captcha token is empty'),
                    code: 502
                })
            }
            return
        }

        setPosting(true)
        await userService.login(form.email, form.password, token)
            .then(r => {
                // eslint-disable-next-line array-callback-return
                r.map(res => {
                    props.onLogin(res.token)
                }).mapError(e => {
                    if (props.onError != null) {
                        props.onError(e)
                    }
                })
                recaptchaRef.current?.reset()
                setPosting(false)
            })
    }

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Card variant="outlined">
                <CardContent>
                    <div className="formHeader">
                        <Typography variant="h6" gutterBottom>
                            <Trans t={t}>
                                Log in to the console
                            </Trans>
                        </Typography>
                    </div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' }
                        }}
                        method="POST"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="loginForm"
                    >
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField label={t('Email address')}
                                style={{ width: '92%' }}
                                variant="outlined"
                                disabled={posting}
                                id="email"
                                {...register('email', { required: true })}
                            />
                            {(errors.email != null) && <div>
                                <Trans t={t}>
                                Email is required
                                </Trans>
                            </div>}
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField label={t('Password')}
                                style={{ width: '92%' }}
                                variant="outlined"
                                disabled={posting}
                                className="formInput"
                                id="password"
                                type="password" {...register('password', { required: true })}
                            />
                            {(errors.password != null) && <div>
                                <Trans t={t}>
                                Password is required
                                </Trans>
                            </div>}
                        </FormControl>
                        <Grid container alignItems="center" justifyContent="center">
                            <Button variant="contained" disabled={posting} type="submit">
                                <Trans t={t}>
                                    Login
                                </Trans>
                            </Button>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}
