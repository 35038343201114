import React from 'react'
import { Course, ListProps } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import {
    Button,
    Grid,
    IconButton
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import { useCourses } from '../shared/hooks/use-courses'
import { DeleteCourseButton } from './buttons/DeleteCourseButton'
import { Table } from '../shared/Table'

export const List: React.FC<ListProps<Course>> = (props) => {
    const { data, loading, setCurrentPage, setSort, error, refresh } = useCourses(true)
    const [t] = useTranslation()

    const columns: GridColDef[] = [
        { field: 'title', headerName: t('Title'), flex: 2 },
        { field: 'authorId', headerName: t('Author'), flex: 1 },
        {
            field: 'creationDate',
            headerName: t('Creation date'),
            flex: 1,
            valueGetter: (r) => {
                const date = r.row.creationDate
                if (!date) {
                    return ''
                }
                return new Date(r.row.creationDate).toLocaleString()
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 100,
            renderCell: r => {
                return <Grid container direction={'row'}>
                    <IconButton onClick={() => {
                        props.onCreate(r.row)
                    }}>
                        <EditIcon />
                    </IconButton>
                    {props.canDelete && <DeleteCourseButton courseId={r.row.id} callback={async () => {
                        await refresh()
                    }} />}
                </Grid>
            }
        }
    ]

    return (
        <Table
            columns={columns}
            error={error}
            loading={loading}
            setCurrentPage={setCurrentPage}
            defaultSort={'creationDate'}
            setSort={setSort}
            data={data}
            selection={true}
            actions={(selectionModel, loading) => {
                return (
                    <Button variant="contained" onClick={() => props.onCreate()}>
                        <Trans t={t}>
                            Create
                        </Trans>
                    </Button>
                )
            }}
        />
    )
}
