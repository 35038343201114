import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import { CourseSelector } from '../CourseSelector'
import React from 'react'
import { useCourses } from '../hooks/use-courses'

export const CourseCell = (props: GridRenderEditCellParams) => {
    const { id, field, row } = props
    const apiRef = useGridApiContext()
    const { getCourses } = useCourses()

    const setValue = async (name: string, value: any) => {
        const courses = await getCourses(value)
        if (courses) {
            await apiRef.current.setEditCellValue({ id, field, value: courses[0].title })
        }
    }

    return (
        <CourseSelector name={'course'}
            values={row.course}
            required={true}
            setValue={setValue}
            fullWidth={true}
            label={''}/>
    )
}
