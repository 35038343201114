import React, { useState } from 'react'
import { StepTemplate } from '../../services/models'
import { Trans, useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Edit } from './Edit'
import { List } from './List'

const stepTemplatesRoutes = {
    list: '/process-steps/list',
    create: '/process-steps/create',
    edit: '/process-steps/edit'
}
export const StepTemplates: React.FC = () => {
    const [currentStepTemplate, setCurrentStepTemplate] = useState<StepTemplate>()
    const [t] = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const editPage = (stepTemplate?: StepTemplate) => {
        setCurrentStepTemplate(stepTemplate)
        navigate(stepTemplate ? `${stepTemplatesRoutes.edit}/${stepTemplate?.id}` : stepTemplatesRoutes.create)
    }

    const pagesMap = () => [
        {
            key: '/list',
            page: <List onCreate={(a) => editPage(a)} canDelete={true} />,
            title: t('Step templates')
        },
        {
            key: '/create',
            page: (
                <Edit
                    onCancel={() => {
                        navigate(stepTemplatesRoutes.list)
                    }}
                    stepTemplate={currentStepTemplate}
                />
            ),
            title: t('Create new step template')
        },
        {
            key: '/edit/:id',
            page: (
                <Edit
                    onCancel={() => {
                        navigate(stepTemplatesRoutes.list)
                    }}
                    stepTemplate={currentStepTemplate}
                />
            ),
            title: currentStepTemplate ? currentStepTemplate.name : t('Create new step template')
        }
    ]

    const pages = pagesMap()

    return (
        <div style={{ minHeight: '400px' }}>
            <div className="Page-title">
                <Typography variant="h5">
                    <Trans t={t}>{pages.find((p) => p.key === location.pathname)?.title}</Trans>
                </Typography>
            </div>
            <Routes>
                {pages.map((m, i) => {
                    return <Route path={m.key} element={m.page} key={i} />
                })}
            </Routes>
        </div>
    )
}
