import { Language, ProcessTemplate, ProcessTemplateRequest } from '../../services/models'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { tokenSelector } from '../../App'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLanguageService } from '../../hooks/use-language-service'
import {
    Alert,
    Box, Button,
    FormControl, Grid, InputLabel, MenuItem, Select,
    TextField, Typography
} from '@mui/material'
import { useProcessTemplateService } from '../../hooks/use-process-template-service'
import { StepSelector } from '../process-steps/controls/StepSelector'
import { useFileService } from '../../hooks/use-file-service'

interface EditProps {
    processTemplate?: ProcessTemplate
    onCancel: () => void
}

export const Edit: React.FC<EditProps> = (props) => {
    const token = useRecoilValue<string | undefined>(tokenSelector)
    const {
        handleSubmit,
        control,
        setValue,
        register
    } = useForm()
    const [t] = useTranslation()
    const { id } = useParams()
    const [current, setCurrent] = useState(props.processTemplate)
    const [error, setError] = useState<string>()
    const [languages, setLanguages] = useState<Language[]>()
    const [loading, setLoading] = useState(false)
    const [imageFileName, setImageFileName] = useState<string>()

    const [processTemplateService] = useProcessTemplateService()
    const [languageService] = useLanguageService()
    const [fileService] = useFileService()

    useEffect(() => {
        if (!token) {
            return
        }
        setLoading(true)
        void languageService.list(token).then((res) => {
            // eslint-disable-next-line array-callback-return
            res.map((languages) => {
                setLanguages(languages)
                setLoading(false)
            }).mapError((e) => {
                setError(e.error)
                setLoading(false)
            })
        })
    }, [token])

    useEffect(() => {
        if (!token) {
            return
        }
        if (id && !props.processTemplate) {
            void processTemplateService.find(token, { id: [id] }).then((r) => {
                r.map((a) => {
                    setCurrent(a.items[0])
                })
            })
        }
    }, [id, token])

    useEffect(() => {
        if (!token) {
            return
        }

        if (current?.image) {
            void fileService.info(token, current.image).then(r => {
                r.map(a => {
                    setImageFileName(a.name)
                })
            })
        }
    }, [id, token])

    const submitRequest = (form: FieldValues, image?: string) => {
        if (!token) {
            setLoading(false)
            return
        }
        const request: ProcessTemplateRequest = {
            name: form.name,
            language: form.language,
            description: form.description,
            duration: form.duration,
            steps: form.steps,
            image
        }

        if (form.tags) {
            const arr: string[] = form.tags.split(',')
            arr.forEach(t => {
                request.tags?.push(t.trim().toLowerCase())
            })
        }
        if (!current) {
            void processTemplateService.create(token, request).then((res) => {
                res
                    // eslint-disable-next-line array-callback-return
                    .map((r) => {
                        setLoading(false)
                        props.onCancel()
                    })
                    .mapError((e) => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        } else {
            void processTemplateService.update(token, current.id, request).then((res) => {
                res
                    // eslint-disable-next-line array-callback-return
                    .map((r) => {
                        setLoading(false)
                        props.onCancel()
                    })
                    .mapError((e) => {
                        setError(e.error)
                        setLoading(false)
                    })
            })
        }
    }

    const onSubmit = (form: FieldValues) => {
        setLoading(true)
        setError(undefined)
        if (form.image && form.image.length > 0) {
            void uploadImageFile(form).then((res) => {
                if (res) {
                    submitRequest(form, res)
                }
            })
        } else {
            submitRequest(form)
        }
    }

    const uploadImageFile = async (form: FieldValues) => {
        if (!token) {
            return undefined
        }
        if (form.image && form.image.length > 0) {
            const res = await fileService.upload(token, form.image)
            let file: string | undefined
            res.map((r) => (file = r.id)).mapError((e) => {
                setError(e.error)
            })
            return file
        }
        return undefined
    }

    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }
        const file = e.target.files[0]
        const { name } = file
        setImageFileName(name)
    }

    return (
        <div>
            {error && <Alert severity="error">{error}</Alert>}
            {(!id || current) && (
                <Box component="form" method="POST" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name={'name'}
                            control={control}
                            defaultValue={current?.name}
                            rules={{ required: 'Name is required' }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Name'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name={'description'}
                            control={control}
                            defaultValue={current?.description}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    multiline={true}
                                    rows={5}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Description'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller
                            name={'duration'}
                            control={control}
                            defaultValue={current?.duration}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    disabled={loading}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    label={'Duration'}
                                    type={'number'}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <InputLabel id="language-label">Language</InputLabel>
                        <Controller
                            name={'language'}
                            control={control}
                            defaultValue={current?.language}
                            render={({ field, fieldState: { error } }) => (
                                <Select {...field} labelId="language-label" disabled={loading}>
                                    {languages?.map((v) => {
                                        return (
                                            <MenuItem key={v.key} value={v.key}>
                                                {v.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Controller name={'tags'} control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth={true}
                                    disabled={loading}
                                    label={t('Tags')}
                                    defaultValue={current?.tags.join(',')}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <Grid container>
                            <Button variant="outlined" component="label" disabled={loading}>
                                <Trans t={t}>Upload image file</Trans>
                                <input type="file" hidden {...register('image', { onChange: handleImageUpload })} />
                            </Button>
                            <Box style={{ padding: '10px ' }}>
                                <Typography variant="body1">{imageFileName}</Typography>
                            </Box>
                        </Grid>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ m: 1 }}>
                        <StepSelector
                            name={'steps'}
                            disabled={loading}
                            control={control}
                            setValue={setValue}
                            values={current?.steps ?? []}
                            label={'Steps'}
                            required={false}
                            multiple={true}
                            table={true}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <Button disabled={loading} variant="contained" type="submit">
                            <Trans t={t}>Save</Trans>
                        </Button>
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <Button disabled={loading} variant="contained" onClick={props.onCancel}>
                            <Trans t={t}>Cancel</Trans>
                        </Button>
                    </FormControl>
                </Box>
            )}
        </div>
    )
}
