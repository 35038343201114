import { ILanguageService } from '../language-service'
import config from '../../config.json'
import { get } from '../connector'
import { Language } from '../models'

export class LanguageService implements ILanguageService {
    private readonly apiUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.apiUrl = process.env.REACT_APP_CONTENT_SERVICE_API_HOST ??
            config.servers.contentServiceUrl
        // eslint-disable-next-line no-console
        console.info(
            'CONTENT_SERVICE_API_HOST: Using defaults, result:',
            this.apiUrl
        )
    }

    list = async (token: string) => {
        const url = `${this.apiUrl}language/list`
        return await get<Language[]>(token, url)
    }
}
