import { IFileAdapter } from '../file-adapter'
import config from '../../config.json'

export class FileAdapter implements IFileAdapter {
    private readonly uploadUrl: string

    /**
     * Ctor
     * */
    constructor () {
        this.uploadUrl = process.env.REACT_APP_UPLOAD_FILE_HOST ??
            config.files.uploadUrl
        // eslint-disable-next-line no-console
        console.info(
            'UPLOAD_FILE_HOST: Using defaults, result:',
            this.uploadUrl
        )
    }

    getUploadUrl = () => {
        return this.uploadUrl
    }

    getHeaders = (token: string | undefined) => {
        if (!token) {
            return {}
        }
        const headers = {
            Authorization: `Bearer ${token}`
        }
        return headers
    }
}
